import { useEffect } from 'react';
import { fetchLatestRelease } from '../api/github/client';
import type { WalletRelease } from '../api/github/types';

const POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes

export function useReleasePolling(
  onSuccess: (data: WalletRelease) => void,
  onError: (error: Error) => void
) {
  useEffect(() => {
    let mounted = true;

    async function pollRelease() {
      try {
        const data = await fetchLatestRelease();
        if (mounted) {
          onSuccess(data);
        }
      } catch (err) {
        if (mounted) {
          onError(err instanceof Error ? err : new Error('Failed to fetch release'));
        }
      }
    }

    pollRelease();
    const interval = setInterval(pollRelease, POLLING_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [onSuccess, onError]);
}