import React from 'react';
import { Code, Globe, Server, Shield } from 'lucide-react';

const CONTRIBUTIONS = [
  {
    icon: Code,
    title: 'Core Development',
    description: 'Contribute to the TWINS Core codebase by implementing new features, optimizing performance, or fixing bugs.',
    rewards: 'Up to 100,000 TWINS per contribution'
  },
  {
    icon: Globe,
    title: 'Network Growth',
    description: 'Help expand the TWINS network by creating tools, documentation, or educational content.',
    rewards: 'Up to 50,000 TWINS per contribution'
  },
  {
    icon: Server,
    title: 'Infrastructure',
    description: 'Support network infrastructure by running seed nodes, block explorers, or other critical services.',
    rewards: '25,000 TWINS per month'
  },
  {
    icon: Shield,
    title: 'Security',
    description: 'Identify and responsibly disclose security vulnerabilities in the TWINS network.',
    rewards: 'Up to 500,000 TWINS per valid report'
  }
];

export default function ContributionsList() {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-8">Contribution Opportunities</h2>
      <div className="grid md:grid-cols-2 gap-6">
        {CONTRIBUTIONS.map((contribution, index) => (
          <div 
            key={index}
            className="bg-slate-800/50 rounded-xl p-6 border border-green-500/10"
          >
            <div className="flex items-center gap-3 mb-4">
              <contribution.icon className="h-6 w-6 text-green-500" />
              <h3 className="text-lg font-semibold">{contribution.title}</h3>
            </div>
            <p className="text-gray-300 mb-4">{contribution.description}</p>
            <div className="text-sm font-mono text-green-400">{contribution.rewards}</div>
          </div>
        ))}
      </div>
    </div>
  );
}