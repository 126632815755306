import { useState, useEffect } from 'react';
import { 
  fetchLastTransactions, 
  analyzeTransactions,
  Transaction,
  TransactionStats 
} from '../api/transactions';
import { API_CONFIG } from '../api/config';

const INITIAL_STATS: TransactionStats = {
  totalVolume: 0,
  avgAmount: 0,
  rewardDistribution: {
    masternodes: 0,
    staking: 0,
    devFund: 0
  },
  volumeHistory: Array(24).fill(0),
  countHistory: Array(24).fill(0)
};

export function useTransactionActivity() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [stats, setStats] = useState<TransactionStats>(INITIAL_STATS);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;
    let retryCount = 0;
    const MAX_RETRIES = 3;

    async function fetchData() {
      if (!mounted) return;
      
      try {
        const txs = await fetchLastTransactions(100);
        if (mounted) {
          setTransactions(txs);
          setStats(analyzeTransactions(txs));
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch transactions'));
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            setTimeout(fetchData, Math.pow(2, retryCount) * 1000);
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchData();
    const interval = setInterval(fetchData, API_CONFIG.REFRESH_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  return { transactions, stats, loading, error };
}