import { useState, useEffect, useCallback } from 'react';

export function useScrollTimeout(timeout: number = 4000) {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = useCallback(() => {
    // Show header when scrolling starts
    setIsVisible(true);
    
    // Clear any existing timeout
    const timeoutId = (window as any).__scrollTimeoutId;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    
    // Set new timeout to hide header
    (window as any).__scrollTimeoutId = setTimeout(() => {
      // Only hide if we're not at the top
      if (window.scrollY > 0) {
        setIsVisible(false);
      }
    }, timeout);
  }, [timeout]);

  useEffect(() => {
    // Initial check
    handleScroll();
    
    // Add scroll listener
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Clear timeout on cleanup
      const timeoutId = (window as any).__scrollTimeoutId;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [handleScroll]);

  // Always show header when at top of page
  useEffect(() => {
    if (window.scrollY === 0) {
      setIsVisible(false);
    }
  }, []);

  return isVisible;
}