import { Clock, CoinsIcon, Wallet, Blocks, Shield, Server, Timer, Coins } from 'lucide-react';
import type { NetworkSpec } from './types';

export const NETWORK_SPECS: NetworkSpec[] = [
  {
    icon: CoinsIcon,
    label: "Max Supply",
    value: "100B TWINS"
  },
  {
    icon: Server,
    label: "Masternode Collateral",
    value: "1M-100M TWINS",
    description: "Tiers: 1M, 5M, 20M, 100M"
  },
  {
    icon: Clock,
    label: "Block Time",
    value: "120s",
    description: "New block every 2 minutes"
  },
  {
    icon: Timer,
    label: "Maturity",
    value: "60 blocks",
    description: "~2 hours confirmation time"
  },
  {
    icon: Shield,
    label: "Staking Age",
    value: "3 hours",
    description: "Minimum time before staking"
  },
  {
    icon: Coins,
    label: "Block Rewards",
    value: "100 TWINS",
    description: "80% MN, 10% POS, 10% Dev"
  },
  {
    icon: Blocks,
    label: "Consensus",
    value: "Proof of Stake",
    description: "Energy efficient validation"
  },
  {
    icon: Wallet,
    label: "Transaction Fee",
    value: "0.0001 TWINS",
    description: "Low-cost transfers"
  }
] as const;