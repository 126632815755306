import React from 'react';
import { Clock, Timer, Activity, Zap, ArrowUp, Box } from 'lucide-react';
import { useNetworkSummary } from '../../hooks/network/useNetworkSummary';
import { useChainAge } from '../../hooks/useChainAge';
import { formatNumber, formatDecimal } from '../../utils/format/number';
import { formatHashrate } from '../../utils/format/network';
import StatRow from './StatRow';
import { NETWORK_CONSTANTS } from '../../constants/network';

export default function BlockHeightWidget() {
  const { data } = useNetworkSummary();
  const { age, avgBlockTime } = useChainAge(data.blockcount);
  
  const blockTimeDeviation = ((avgBlockTime - NETWORK_CONSTANTS.TARGET_BLOCK_TIME) / NETWORK_CONSTANTS.TARGET_BLOCK_TIME) * 100;
  const isBlockTimeNormal = Math.abs(blockTimeDeviation) <= 5;
  
  return (
    <div className="h-full col-span-1 md:col-span-2 min-w-[300px] bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center space-x-2 mb-4 md:mb-6">
        <Box className="h-5 w-5 md:h-6 md:w-6 text-green-500" />
        <h3 className="text-lg md:text-xl font-semibold">Chain Status</h3>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 h-[calc(100%-3.5rem)]">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-sm text-gray-400 mb-1 md:mb-2">Current Block</div>
            <div className="text-2xl md:text-3xl font-mono text-green-400">
              #{formatNumber(data.blockcount)}
            </div>
            <div className="text-xs md:text-sm text-gray-400 mt-1">
              Chain Age: {age}
            </div>
          </div>

          <div className="grid gap-2 md:gap-3">
            <StatRow
              icon={<Activity className="h-4 w-4" />}
              label="Online Masternodes"
              value={`${formatNumber(data.masternodeCountOnline)}`}
            />
            <StatRow
              icon={<Zap className="h-4 w-4" />}
              label="Network Hashrate"
              value={formatHashrate(data.hashrate)}
            />
          </div>
        </div>
        
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-sm text-gray-400 mb-1 md:mb-2">Block Time</div>
            <div className="text-xl md:text-2xl font-mono text-green-400">
              {formatDecimal(avgBlockTime, 1)}s
            </div>
            <div className="text-xs md:text-sm text-gray-400 mt-1">
              Target: {NETWORK_CONSTANTS.TARGET_BLOCK_TIME}s
            </div>
          </div>
          
          <div className="p-3 md:p-4 bg-slate-900/50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <Timer className="h-3 w-3 md:h-4 md:w-4 text-green-500" />
                <span className="text-xs md:text-sm text-gray-400">Block Time Health</span>
              </div>
              <span className={`text-xs md:text-sm ${isBlockTimeNormal ? 'text-green-400' : 'text-green-400'}`}>
                {Math.abs(blockTimeDeviation).toFixed(1)}%
                {blockTimeDeviation !== 0 && (
                  <ArrowUp className={`inline-block h-3 w-3 ml-1 ${
                    blockTimeDeviation < 0 ? 'rotate-180' : ''
                  }`} />
                )}
              </span>
            </div>
            <div className="h-1.5 md:h-2 bg-slate-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-green-500 rounded-full transition-all duration-500"
                style={{ 
                  width: `${Math.min(100, Math.max(0, (avgBlockTime / NETWORK_CONSTANTS.TARGET_BLOCK_TIME) * 100))}%` 
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}