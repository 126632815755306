import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DevFundContent from '../components/devfund/DevFundContent';

export default function DevFund() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
      <Header />
      <div className="container mx-auto px-6 py-32">
        <DevFundContent />
      </div>
      <Footer />
    </div>
  );
}