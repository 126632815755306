import { useState, useEffect } from 'react';
import { fetchMasternodeList, fetchNodeBalance, Masternode } from '../api/masternodes';
import { fetchNodeTransactions, analyzeRewards, RewardStats } from '../api/masternodes/transactions';
import { API_CONFIG } from '../api/config';

const TIERS = [1_000_000, 5_000_000, 20_000_000, 100_000_000];

export interface MasternodeWithStats extends Masternode {
  balance?: number;
  tier?: number;
  rewardStats?: RewardStats;
}

export function useMasternodes() {
  const [nodes, setNodes] = useState<MasternodeWithStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        const list = await fetchMasternodeList();
        
        const nodesWithData = await Promise.all(
          list.map(async (node) => {
            const [balance, transactions] = await Promise.all([
              fetchNodeBalance(node.addr),
              fetchNodeTransactions(node.addr)
            ]);

            const tier = TIERS.reduce((acc, tier) => 
              balance >= tier ? tier : acc, TIERS[0]
            );

            const rewardStats = analyzeRewards(transactions);

            return { 
              ...node, 
              balance, 
              tier,
              rewardStats
            };
          })
        );

        if (mounted) {
          setNodes(nodesWithData);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch masternodes'));
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchData();
    const interval = setInterval(fetchData, API_CONFIG.REFRESH_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  return { nodes, loading, error };
}