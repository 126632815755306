import React, { useState, useEffect } from 'react';
import { formatDuration, formatTimestamp } from '../../utils/format/time';
import TwinsAmount from '../common/TwinsAmount';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';

interface MasternodeRowProps {
  node: MasternodeWithStats;
}

export default function MasternodeRow({ node }: MasternodeRowProps) {
  const [rewardsCounter, setRewardsCounter] = useState(0);
  const targetRewards = Math.round(node.rewardStats?.rewardsPerDay || 0);
  const isEnabled = node.status === 'ENABLED';
  
  // Animate rewards counter
  useEffect(() => {
    if (rewardsCounter === targetRewards) return;
    
    const step = Math.ceil(targetRewards / 20); // Complete in 20 steps
    const interval = setInterval(() => {
      setRewardsCounter(current => {
        const next = Math.min(current + step, targetRewards);
        if (next === targetRewards) clearInterval(interval);
        return next;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [targetRewards]);
  
  const formatFrequency = (interval: number) => {
    if (!interval) return 'N/A';
    
    const days = Math.floor(interval / 86400);
    const hours = Math.floor((interval % 86400) / 3600);
    const minutes = Math.floor((interval % 3600) / 60);

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);

    return parts.length > 0 ? parts.join(' ') : '< 1m';
  };

  return (
    <tr className="border-b border-green-500/10 hover:bg-slate-700/30">
      <td className="px-6 py-4 text-right">
        <span className="font-mono text-green-400">#{node.rank}</span>
      </td>
      <td className="px-6 py-4">
        <div className="font-mono text-sm text-gray-300">{node.addr}</div>
      </td>
      <td className="px-6 py-4">
        <div className="flex items-center gap-2">
          <div className={`w-2 h-2 rounded-full ${isEnabled ? 'bg-green-400' : 'bg-red-400'}`} />
          <span className="text-gray-300">{formatDuration(node.activetime)}</span>
        </div>
      </td>
      <td className="px-6 py-4 text-right">
        <TwinsAmount amount={node.tier || 0} />
      </td>
      <td className="px-6 py-4 text-right">
        <div className={`transition-opacity duration-200 ${
          rewardsCounter === targetRewards ? 'opacity-100' : 'opacity-70'
        }`}>
          <TwinsAmount amount={rewardsCounter} />
        </div>
      </td>
      <td className="px-6 py-4">
        {node.rewardStats?.lastPaid ? (
          <span className="text-gray-300">
            {formatTimestamp(node.rewardStats.lastPaid)}
          </span>
        ) : (
          <span className="text-gray-400">Never</span>
        )}
      </td>
      <td className="px-6 py-4">
        <span className="text-gray-300">
          {formatFrequency(node.rewardStats?.averageInterval || 0)}
        </span>
      </td>
    </tr>
  );
}