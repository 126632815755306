import React from 'react';
import TwinsAmount from '../../icons/TwinsAmount';
import { formatNumber } from '../../../utils/format/number';

interface SupplyOverviewProps {
  total: number;
  circulating: number;
}

export default function SupplyOverview({ total, circulating }: SupplyOverviewProps) {
  return (
    <div className="space-y-4">
      <div>
        <div className="text-sm text-gray-400 mb-2">Total Supply</div>
        <TwinsAmount 
          amount={formatNumber(total)}
          size="lg"
          className="text-xl md:text-2xl text-green-400"
        />
      </div>
      
      <div>
        <div className="text-sm text-gray-400 mb-2">Circulating</div>
        <TwinsAmount 
          amount={formatNumber(circulating)}
          className="text-lg md:text-xl text-green-400"
        />
      </div>
    </div>
  );
}