import React from 'react';
import { motion } from 'framer-motion';
import type { NetworkSpec } from './types';

interface SpecsGridProps {
  specs: NetworkSpec[];
}

export default function SpecsGrid({ specs }: SpecsGridProps) {
  return (
    <div className="grid md:grid-cols-4 gap-6">
      {specs.map((spec, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          className="bg-slate-900/50 p-6 rounded-lg border border-green-500/10 backdrop-blur-sm
            hover:border-green-500/20 transition-colors"
        >
          <div className="flex items-center gap-3 mb-4">
            <spec.icon className="h-5 w-5 text-green-500" />
            <h3 className="font-semibold text-sm text-gray-400">{spec.label}</h3>
          </div>
          <div className="space-y-2">
            <div className="text-xl font-mono text-green-400">{spec.value}</div>
            {spec.description && (
              <p className="text-sm text-gray-400">{spec.description}</p>
            )}
          </div>
        </motion.div>
      ))}
    </div>
  );
}