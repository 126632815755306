import React, { createContext, useContext, useCallback, useState } from 'react';
import type { WalletRelease } from '../api/github/types';
import { useReleasePolling } from '../hooks/useReleasePolling';

interface ReleaseContextType {
  release: WalletRelease | null;
  loading: boolean;
  error: Error | null;
}

const ReleaseContext = createContext<ReleaseContextType | undefined>(undefined);

export function ReleaseProvider({ children }: { children: React.ReactNode }) {
  const [release, setRelease] = useState<WalletRelease | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const handleSuccess = useCallback((data: WalletRelease) => {
    setRelease(data);
    setError(null);
    setLoading(false);
  }, []);

  const handleError = useCallback((err: Error) => {
    setError(err);
    setLoading(false);
  }, []);

  useReleasePolling(handleSuccess, handleError);

  return (
    <ReleaseContext.Provider value={{ release, loading, error }}>
      {children}
    </ReleaseContext.Provider>
  );
}

export function useRelease() {
  const context = useContext(ReleaseContext);
  if (context === undefined) {
    throw new Error('useRelease must be used within a ReleaseProvider');
  }
  return context;
}