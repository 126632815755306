import { formatNumber as baseFormatNumber } from './base';

export function formatAmount(num: number): string {
  if (num >= 1_000_000_000) {
    return `${(num / 1_000_000_000).toFixed(1)}B`;
  }
  if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1)}M`;
  }
  return baseFormatNumber(num);
}

export { 
  formatNumber,
  formatCompactNumber,
  formatDecimal 
} from './base';