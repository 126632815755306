import { GithubRelease, WalletRelease } from './types';
import { FALLBACK_RELEASE } from './constants';

const GITHUB_API = 'https://api.github.com/repos/NewCapital/TWINS-Core/releases/latest';
const CACHE_KEY = 'twins_latest_release';
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

interface CachedRelease {
  data: WalletRelease;
  timestamp: number;
}

function getCachedRelease(): WalletRelease | null {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    const { data, timestamp }: CachedRelease = JSON.parse(cached);
    if (Date.now() - timestamp > CACHE_TTL) return null;

    return data;
  } catch {
    return null;
  }
}

function cacheRelease(release: WalletRelease) {
  try {
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data: release,
      timestamp: Date.now()
    }));
  } catch {
    // Ignore storage errors
  }
}

export async function fetchLatestRelease(): Promise<WalletRelease> {
  // Try to get cached release first
  const cached = getCachedRelease();
  if (cached) return cached;

  try {
    const response = await fetch(GITHUB_API, {
      headers: {
        'Accept': 'application/vnd.github.v3+json',
        'User-Agent': 'TWINS-Website'
      }
    });

    if (!response.ok) {
      throw new Error(`GitHub API error: ${response.status}`);
    }

    const release: GithubRelease = await response.json();
    
    const walletRelease = {
      version: release.tag_name.replace('twins_v', ''),
      publishedAt: release.published_at,
      assets: {
        windows: {
          x32: release.assets.find(a => a.name.includes('win32'))?.browser_download_url,
          x64: release.assets.find(a => a.name.includes('win64'))?.browser_download_url,
        },
        mac: release.assets.find(a => a.name.includes('osx'))?.browser_download_url,
        linux: {
          aarch64: release.assets.find(a => a.name.includes('aarch64'))?.browser_download_url,
          arm: release.assets.find(a => a.name.includes('arm-linux'))?.browser_download_url,
          x86: release.assets.find(a => a.name.includes('i686'))?.browser_download_url,
          x64: release.assets.find(a => a.name.includes('x86_64'))?.browser_download_url,
        }
      }
    };

    // Cache the successful response
    cacheRelease(walletRelease);
    return walletRelease;
  } catch (error) {
    console.warn('Failed to fetch release:', error);
    return FALLBACK_RELEASE;
  }
}