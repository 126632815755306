export const PRIVACY_SECTIONS = [
  {
    title: "Introduction",
    content: [
      'This is a notice to inform you of our policy about all information that we record about you. It sets out the conditions under which we may process any information that we collect from you, or that you provide to us. It covers information that could identify you ("personal information") and information that could not. In the context of the law and this notice, "process" means collect, store, transfer, use or otherwise act on information.',
      "We regret that if there is any information in this Privacy Policy with which you disagree, your only recourse is to leave our website immediately.",
      "We take the protection of your privacy and confidentiality seriously. We understand that all visitors to our website are entitled to know that their personal data will not be used for any purpose unintended by them, and will not accidentally fall into the hands of a third party.",
      "We undertake to preserve the confidentiality of all information you provide to us.",
      "Our policy complies with Ireland law accordingly implemented, including that required by the EU General Data Protection Regulation (GDPR).",
      "The law requires us to tell you about your rights and our obligations to you regarding the processing and control of your personal data. To comply with this law, we therefore request that you read the information provided at www.knowyourprivacyrights.org",
      "Except as set out below, we do not share, sell, or disclose to a third party, any information collected through our website."
    ]
  },
  {
    title: "What Information We Collect and How We Use It",
    content: [
      "When you visit our website, we collect cookies, pixels, and browser-related data to improve the browsing experience and accommodate the design of our website.",
      "When you register and create an account, we collect your name, surname, email, phone number, IP address, VAT ID or Tax ID, residence address, geographic location, and date and time of your request. This information is used to create your user account and enable us to contact you regarding account management.",
      "For account verification, we collect a photo of you holding your passport or national ID card, including document number and expiration date.",
      "For purchases, we collect your name, surname, billing address, and payment method details to process transactions and comply with legal requirements."
    ]
  },
  {
    title: "Retention Period for Personal Data",
    content: [
      "Except as otherwise mentioned in this privacy notice, we keep your personal information only for as long as required by us:",
      "• to provide you with the services you have requested;",
      "• to comply with other laws, including for the period demanded by tax authorities (this will typically be a period of 7 years);",
      "• to support a claim or defence in court.",
      "If you have not purchased or otherwise used any of our services, your personal information may be deleted by deleting your account."
    ]
  }
] as const;