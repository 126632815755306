import React from 'react';
import { Twitter, Github } from 'lucide-react';
import NavLink from './NavLink';
import { scrollToWallets } from '../../utils/scroll';
import { useLocation } from 'react-router-dom';
import { useMobileMenu } from '../../hooks/useMobileMenu';

export default function MainNav() {
  const { isOpen, close } = useMobileMenu();
  const location = useLocation();

  const handleWalletClick = (e: React.MouseEvent) => {
    e.preventDefault();
    close();
    if (location.pathname !== '/') {
      window.location.href = '/#wallet-downloads';
    } else {
      scrollToWallets();
    }
  };

  return (
    <>
      <nav className="hidden md:flex items-center justify-between w-full">
        {/* Desktop Navigation */}
        <div className="flex items-center space-x-8">
          <NavLink href="https://shop.new.capital/collections/twins" external>
            Shop
          </NavLink>
          <NavLink href="https://explorer.win.win/" external>
            Explorer
          </NavLink>
          <NavLink href="https://docs.win.win/twins" external>
            Guides
          </NavLink>
          <NavLink href="#wallet-downloads" onClick={handleWalletClick}>
            Wallets
          </NavLink>
        </div>

        {/* Desktop Social Links */}
        <div className="flex items-center space-x-4">
          <a 
            href="https://twitter.com/TWINS_Coin" 
            className="text-gray-400 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter className="h-5 w-5" />
          </a>
          <a 
            href="https://github.com/NewCapital/TWINS-Core" 
            className="text-gray-400 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Github className="h-5 w-5" />
          </a>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div 
        className={`fixed top-[72px] left-0 right-0 bottom-0 md:hidden bg-slate-900/95 backdrop-blur-md transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="p-6 space-y-6">
          <div className="space-y-4">
            <NavLink 
              href="https://shop.new.capital/collections/twins" 
              external
              className="block text-lg"
              onClick={close}
            >
              Shop
            </NavLink>
            <NavLink 
              href="https://explorer.win.win/" 
              external
              className="block text-lg"
              onClick={close}
            >
              Explorer
            </NavLink>
            <NavLink 
              href="https://docs.win.win/twins" 
              external
              className="block text-lg"
              onClick={close}
            >
              Guides
            </NavLink>
            <NavLink 
              href="#wallet-downloads"
              onClick={handleWalletClick}
              className="block text-lg"
            >
              Wallets
            </NavLink>
          </div>

          <div className="pt-6 border-t border-green-500/10 flex items-center space-x-4">
            <a 
              href="https://twitter.com/TWINS_Coin" 
              className="text-gray-400 hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              <Twitter className="h-5 w-5" />
            </a>
            <a 
              href="https://github.com/NewCapital/TWINS-Core" 
              className="text-gray-400 hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              <Github className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}