import React from 'react';
import { TrendingUp, Calendar, Clock } from 'lucide-react';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';
import { calculateROI, formatROI } from '../../utils/calculations/roi';

interface ROIWidgetProps {
  node: MasternodeWithStats;
}

export default function ROIWidget({ node }: ROIWidgetProps) {
  const roi = calculateROI(node);

  return (
    <div className="bg-slate-800/50 rounded-lg md:rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center gap-2 md:gap-3 mb-4 md:mb-6">
        <TrendingUp className="h-4 w-4 md:h-5 md:w-5 text-green-500" />
        <h3 className="text-base md:text-lg font-semibold">Return on Investment</h3>
      </div>

      <div className="grid grid-cols-3 gap-3 md:gap-4">
        <div className="p-3 md:p-4 bg-slate-900/50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <Clock className="h-3 w-3 md:h-4 md:w-4 text-green-500" />
            <span className="text-xs md:text-sm text-gray-400">Daily</span>
          </div>
          <div className="text-base md:text-xl font-mono text-green-400">
            {formatROI(roi.daily)}
          </div>
        </div>

        <div className="p-3 md:p-4 bg-slate-900/50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <Calendar className="h-3 w-3 md:h-4 md:w-4 text-green-500" />
            <span className="text-xs md:text-sm text-gray-400">Monthly</span>
          </div>
          <div className="text-base md:text-xl font-mono text-green-400">
            {formatROI(roi.monthly)}
          </div>
        </div>

        <div className="p-3 md:p-4 bg-slate-900/50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <TrendingUp className="h-3 w-3 md:h-4 md:w-4 text-green-500" />
            <span className="text-xs md:text-sm text-gray-400">Yearly</span>
          </div>
          <div className="text-base md:text-xl font-mono text-green-400">
            {formatROI(roi.yearly)}
          </div>
        </div>
      </div>
    </div>
  );
}