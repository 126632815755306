import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { formatAmount } from '../../../utils/format/number';
import type { DistributionData } from '../../../types/distribution';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DistributionChartProps {
  data: DistributionData;
}

const RANGE_LABELS = {
  t_1_25: 'Top 1-25',
  t_26_50: 'Top 26-50',
  t_51_75: 'Top 51-75',
  t_76_100: 'Top 76-100',
  t_101plus: 'Top 101+'
} as const;

export default function DistributionChart({ data }: DistributionChartProps) {
  const ranges = ['t_1_25', 't_26_50', 't_51_75', 't_76_100', 't_101plus'] as const;
  
  const chartData: ChartData<'doughnut'> = {
    labels: ranges.map(range => RANGE_LABELS[range]),
    datasets: [{
      data: ranges.map(range => parseFloat(data[range].percent)),
      backgroundColor: ranges.map((_, i) => 
        `hsl(${120 - i * 15}, 60%, ${45 - i * 5}%)`
      ),
      borderColor: '#1e293b',
      borderWidth: 2,
      hoverOffset: 4
    }]
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(15, 23, 42, 0.9)',
        titleColor: '#94a3b8',
        bodyColor: '#ffffff',
        padding: 12,
        boxPadding: 8,
        usePointStyle: true,
        callbacks: {
          label: (context) => {
            const value = context.raw as number;
            const total = parseFloat(data[ranges[context.dataIndex]].total);
            return [
              `${value.toFixed(2)}%`,
              `${formatAmount(total)} TWINS`
            ];
          }
        }
      }
    }
  };

  return (
    <div className="relative aspect-square">
      <Doughnut data={chartData} options={options} />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <div className="text-xs text-gray-400">Total Supply</div>
          <div className="text-lg font-mono text-green-400">
            {formatAmount(data.supply)}
          </div>
          <div className="text-xs text-gray-400">TWINS</div>
        </div>
      </div>
    </div>
  );
}