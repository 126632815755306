import React from 'react';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  external?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default function NavLink({ href, children, external, className = '', onClick }: NavLinkProps) {
  return (
    <a 
      href={href} 
      className={`hover:text-[#5CB54B] transition-colors ${className}`}
      target={external ? "_blank" : undefined}
      rel={external ? "noopener noreferrer" : undefined}
      onClick={onClick}
    >
      {children}
    </a>
  );
}