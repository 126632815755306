import React from 'react';
import { ArrowDown } from 'lucide-react';
import BrandLogo from './brand/BrandLogo';
import BlockHeightIndicator from './blockchain/BlockHeightIndicator';

export default function Hero() {
  return (
    <section className="min-h-screen relative">
      <div 
        className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')] 
        bg-cover bg-center"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-slate-900/90 to-slate-900/50" />
      </div>
      
      <div className="relative container mx-auto px-6 pt-32 pb-24">
        <div className="flex flex-col items-center justify-center min-h-[70vh] text-center">
          <div className="mt-[50px]">
            <BrandLogo size="large" className="mb-2" />
          </div>
          <p className="text-xl text-gray-300 mb-12">peer-to-peer crypto</p>
          
          <BlockHeightIndicator />
        </div>

        <button 
          onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
          className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 text-white/50 hover:text-white transition-colors"
          aria-label="Scroll down"
        >
          <ArrowDown className="h-8 w-8 animate-bounce" />
        </button>
      </div>
    </section>
  );
}