import React from 'react';
import { Network } from 'lucide-react';

export default function SpecsHeader() {
  return (
    <div className="text-center mb-16 animate-fade-in">
      <div className="flex items-center justify-center gap-3 mb-6">
        <Network className="h-8 w-8 text-green-500" />
        <h2 className="text-4xl font-bold">Network Specifications</h2>
      </div>
      <p className="text-gray-400 max-w-2xl mx-auto">
        TWINS combines Proof of Stake consensus with masternode technology to create 
        a secure, scalable, and energy-efficient blockchain network.
      </p>
    </div>
  );
}