export const WHITEPAPER_SECTIONS = [
  {
    title: "TWINS Cryptocurrency: A Green, Fast, Scalable, and Decentralized P2P Value Exchange Platform",
    content: [
      "The TWINS cryptocurrency project represents a significant advancement in blockchain technology, combining proven concepts with innovative solutions to create a more efficient, sustainable, and user-friendly platform for peer-to-peer value exchange.",
      "This whitepaper outlines our comprehensive approach to addressing key challenges in the cryptocurrency space, including volatility, scalability, and environmental impact, while maintaining the core principles of decentralization and security."
    ]
  },
  {
    title: "Background and Origins",
    content: [
      "The win.win project was conceived with a vision to create a truly decentralized platform that empowers individuals and organizations to engage in peer-to-peer value exchange, free from the limitations of traditional systems.",
      "Founded by a diverse team with expertise spanning IT, telecommunications, cybersecurity, legal, and financial markets, the project launched on December 31, 2018, utilizing a fork of the PIVX codebase with Proof of Stake (PoS) consensus.",
      "From its inception, the project prioritized equitable distribution, with all pre-mined TWINS distributed among hundreds of community members who joined through Bitcoin and masternode forums."
    ]
  },
  {
    title: "First Generation Network Architecture",
    content: [
      "The initial TWINS network leveraged the PIVX codebase, incorporating advanced privacy features through the Zerocoin protocol and enabling fast transaction processing with a 60-second block time.",
      "The Proof of Stake (PoS) consensus mechanism was chosen for its energy efficiency and enhanced security compared to Proof of Work (PoW) systems, contributing to increased resistance against 51% attacks and maintaining network decentralization.",
      "TWINS coin specifications include a maximum supply of 100 billion, 120-second block time, 60-block maturity, and a 3-hour staking age. The block reward structure allocates 80% to Masternodes, 10% to Stakers, and 10% to the Developer Fund."
    ]
  },
  {
    title: "Strategic Migration to Symbol Platform",
    content: [
      "To enhance functionality and scalability, the project is migrating to a Symbol (XYM) codebase fork. This strategic move enables advanced features including:",
      "• Asset tokenization and management",
      "• Delegated staking and harvesting",
      "• Proof of Importance (PoI) consensus",
      "• Multi-level multi-signature accounts",
      "• Customizable smart contracts",
      "• Enhanced interoperability",
      "The migration process includes extensive testing on testnet, followed by a transparent mainnet launch and coin swap process."
    ]
  },
  {
    title: "Dynamic Block Reward Algorithm",
    content: [
      "A key innovation in the TWINS network is the dynamic block reward algorithm that automatically adjusts issuance rates based on market conditions. This mechanism helps combat volatility and promote price stability through:",
      "• Market-responsive issuance rates",
      "• Automatic supply adjustments based on demand",
      "• Sophisticated price monitoring and adjustment formulas",
      "• Transparent and predictable reward calculations"
    ]
  },
  {
    title: "Governance and Community",
    content: [
      "The TWINS project embraces a decentralized governance model that empowers the community through:",
      "• Open-source development and community contributions",
      "• Transparent decision-making processes",
      "• Community voting on key protocol changes",
      "• Regular communication and feedback channels",
      "This approach ensures the platform evolves according to user needs while maintaining decentralization."
    ]
  },
  {
    title: "Future Development",
    content: [
      "The project's roadmap includes several key initiatives:",
      "• Enhanced cross-chain interoperability",
      "• Advanced privacy features",
      "• Expanded smart contract capabilities",
      "• Improved scalability solutions",
      "• Integration with DeFi protocols",
      "These developments will further strengthen TWINS' position as a leading platform for decentralized value exchange."
    ]
  }
] as const;