import React from 'react';
import WhitepaperHeader from './WhitepaperHeader';
import WhitepaperSection from './WhitepaperSection';
import { WHITEPAPER_SECTIONS } from '../../constants/whitepaper';

export default function WhitepaperContent() {
  return (
    <div className="max-w-4xl mx-auto">
      <WhitepaperHeader />
      <div className="space-y-16">
        {WHITEPAPER_SECTIONS.map((section, index) => (
          <WhitepaperSection 
            key={index}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
    </div>
  );
}