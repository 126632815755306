import React from 'react';
import { Network } from 'lucide-react';
import { useRelease } from '../../contexts/ReleaseContext';

interface VersionDisplayProps {
  className?: string;
}

export default function VersionDisplay({ className = '' }: VersionDisplayProps) {
  const { release, loading, error } = useRelease();

  if (loading) {
    return (
      <div className={`flex items-center gap-2 ${className}`}>
        <Network className="h-5 w-5 text-green-500" />
        <div className="h-5 w-24 bg-slate-700 animate-pulse rounded" />
      </div>
    );
  }

  if (error || !release) {
    return null;
  }

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <Network className="h-5 w-5 text-green-500" />
      <span className="text-sm font-mono">Core Version: v{release.version}</span>
    </div>
  );
}