import { API_CONFIG } from './config';

interface FetchOptions extends RequestInit {
  useProxy?: boolean;
}

export async function fetchWithTimeout(
  endpoint: string, 
  options: FetchOptions = {}
): Promise<Response> {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), API_CONFIG.TIMEOUT);
  const { useProxy = true, ...fetchOptions } = options;

  try {
    const url = useProxy 
      ? `${API_CONFIG.CORS_PROXY}${encodeURIComponent(API_CONFIG.BASE_URL + endpoint)}`
      : API_CONFIG.BASE_URL + endpoint;

    const response = await fetch(url, {
      ...fetchOptions,
      signal: controller.signal,
      headers: {
        'Accept': 'application/json',
        ...fetchOptions.headers
      }
    });
    
    return response;
  } finally {
    clearTimeout(id);
  }
}