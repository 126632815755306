import React from 'react';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';
import StatCard from './StatCard';
import CollateralWidget from './CollateralWidget';
import NodeStatusWidget from './NodeStatusWidget';
import ROIWidget from './ROIWidget';
import TierSummary from './TierSummary';
import { useNetwork } from '../../contexts/NetworkContext';
import { Server } from 'lucide-react';

interface MasternodeStatsProps {
  nodes: MasternodeWithStats[];
  loading: boolean;
}

export default function MasternodeStats({ nodes, loading }: MasternodeStatsProps) {
  const { data: networkData } = useNetwork();

  if (loading) {
    return (
      <div className="space-y-4 md:space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-slate-800/50 rounded-lg md:rounded-xl p-4 md:p-6 border border-green-500/10 animate-pulse">
              <div className="h-20"></div>
            </div>
          ))}
        </div>
        <div className="bg-slate-800/50 rounded-lg md:rounded-xl p-4 md:p-6 border border-green-500/10 animate-pulse">
          <div className="h-32"></div>
        </div>
      </div>
    );
  }

  const totalNodes = nodes.length;

  return (
    <div className="space-y-4 md:space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
        <StatCard
          icon={Server}
          label="Total Nodes"
          value={totalNodes.toString()}
          subValue={<TierSummary nodes={nodes} />}
        />
        <CollateralWidget 
          nodes={nodes}
          totalSupply={networkData.supply}
        />
        <NodeStatusWidget nodes={nodes} />
      </div>

      {nodes.length > 0 && <ROIWidget node={nodes[0]} />}
    </div>
  );
}