export const ENDPOINTS = {
  CORE: {
    BLOCK_COUNT: '/api/getblockcount',
    DIFFICULTY: '/api/getdifficulty',
    HASHRATE: '/api/getnetworkhashps',
    CONNECTIONS: '/api/getconnectioncount',
    MASTERNODE_COUNT: '/api/getmasternodecount',
    BLOCK_HASH: (index: number) => `/api/getblockhash?index=${index}`,
    BLOCK: (hash: string) => `/api/getblock?hash=${hash}`,
  },
  EXTENDED: {
    MONEY_SUPPLY: '/ext/getmoneysupply',
    BASIC_STATS: '/ext/getbasicstats',
    SUMMARY: '/ext/getsummary',
    CURRENT_PRICE: '/ext/getcurrentprice',
    DISTRIBUTION: '/ext/getdistribution'
  }
} as const;