import React from 'react';
import VersionDisplay from '../version/VersionDisplay';

export default function WalletHeader() {
  return (
    <div className="text-center mb-16">
      <h2 className="text-4xl font-bold mb-4">TWINS Core Node</h2>
      <div className="max-w-3xl mx-auto">
        <p className="text-gray-300 mb-6 leading-relaxed">
          Deploy a sovereign TWINS node and participate in securing the network's distributed consensus. 
          Execute peer-to-peer transactions, validate blocks, and earn rewards through staking or masternode operations. 
          Your node strengthens the network's decentralization while providing direct blockchain access without intermediaries.
        </p>
        <VersionDisplay className="justify-center text-green-400" />
      </div>
    </div>
  );
}