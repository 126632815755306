import React, { useState } from 'react';
import type { Masternode } from '../../api/masternodes';
import MasternodeListHeader from './MasternodeListHeader';
import MasternodeRow from './MasternodeRow';
import MasternodeCard from './MasternodeCard';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface MasternodeListProps {
  nodes: Masternode[];
  loading: boolean;
  error: Error | null;
}

export default function MasternodeList({ nodes, loading, error }: MasternodeListProps) {
  const [sortField, setSortField] = useState<keyof Masternode>('rank');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (loading) {
    return (
      <div className="bg-slate-800/50 rounded-xl border border-green-500/10">
        <div className="p-6 animate-pulse space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-16 bg-slate-700/50 rounded-lg" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-slate-800/50 rounded-xl p-6 border border-green-500/10">
        <div className="text-red-400 text-center">
          Failed to load masternode data
        </div>
      </div>
    );
  }

  const handleSort = (field: keyof Masternode) => {
    if (field === sortField) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedNodes = [...nodes].sort((a, b) => {
    const aValue = a[sortField];
    const bValue = b[sortField];
    return sortDirection === 'asc' 
      ? (aValue > bValue ? 1 : -1)
      : (aValue < bValue ? 1 : -1);
  });

  if (isMobile) {
    return (
      <div className="space-y-4">
        {sortedNodes.map((node) => (
          <MasternodeCard key={node.txhash} node={node} />
        ))}
      </div>
    );
  }

  return (
    <div className="bg-slate-800/50 rounded-xl border border-green-500/10">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <MasternodeListHeader 
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={handleSort}
            />
          </thead>
          <tbody>
            {sortedNodes.map((node) => (
              <MasternodeRow key={node.txhash} node={node} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}