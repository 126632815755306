import React from 'react';
import { Server, Clock, Coins, Zap, Timer } from 'lucide-react';
import { formatDuration, formatTimestamp } from '../../utils/format/time';
import TwinsAmount from '../common/TwinsAmount';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';

interface MasternodeCardProps {
  node: MasternodeWithStats;
}

export default function MasternodeCard({ node }: MasternodeCardProps) {
  const isEnabled = node.status === 'ENABLED';
  
  return (
    <div className="bg-slate-800/50 rounded-lg p-4 border border-green-500/10">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <Server className="h-4 w-4 text-green-500" />
          <span className="font-mono text-green-400">#{node.rank}</span>
        </div>
        <div className="flex items-center gap-2">
          <div className={`w-2 h-2 rounded-full ${isEnabled ? 'bg-green-400' : 'bg-red-400'}`} />
          <span className="text-sm text-gray-400">{isEnabled ? 'Online' : 'Offline'}</span>
        </div>
      </div>

      <div className="space-y-3">
        <div>
          <div className="text-xs text-gray-400 mb-1">Address</div>
          <div className="font-mono text-sm text-gray-300 break-all">{node.addr}</div>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <div>
            <div className="flex items-center gap-1.5 text-xs text-gray-400 mb-1">
              <Clock className="h-3 w-3" />
              <span>Active Time</span>
            </div>
            <div className="text-sm text-gray-300">{formatDuration(node.activetime)}</div>
          </div>

          <div>
            <div className="flex items-center gap-1.5 text-xs text-gray-400 mb-1">
              <Coins className="h-3 w-3" />
              <span>Collateral</span>
            </div>
            <TwinsAmount amount={node.tier || 0} className="text-sm" />
          </div>

          <div>
            <div className="flex items-center gap-1.5 text-xs text-gray-400 mb-1">
              <Zap className="h-3 w-3" />
              <span>Daily Rewards</span>
            </div>
            <TwinsAmount 
              amount={Math.round(node.rewardStats?.rewardsPerDay || 0)} 
              className="text-sm" 
            />
          </div>

          <div>
            <div className="flex items-center gap-1.5 text-xs text-gray-400 mb-1">
              <Timer className="h-3 w-3" />
              <span>Last Reward</span>
            </div>
            <div className="text-sm text-gray-300">
              {node.rewardStats?.lastPaid ? 
                formatTimestamp(node.rewardStats.lastPaid) : 
                'Never'
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}