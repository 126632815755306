import { API_CONFIG } from './config';
import { fetchWithTimeout } from './fetch';
import { createTimeSlots } from '../utils/time';

export interface Transaction {
  blockindex: number;
  blockhash: string;
  txid: string;
  recipients: number;
  amount: number;
  timestamp: number;
}

export interface TransactionStats {
  totalVolume: number;
  avgAmount: number;
  rewardDistribution: {
    masternodes: number;
    staking: number;
    devFund: number;
  };
  timeRange: {
    start: number;
    end: number;
  };
  volumeHistory: Array<{
    timestamp: number;
    value: number;
  }>;
}

export async function fetchLastTransactions(count: number = 100): Promise<Transaction[]> {
  try {
    const response = await fetchWithTimeout(`/ext/getlasttxs/0/0/${count}`);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.warn('Transactions fetch error:', error);
    return [];
  }
}

export function analyzeTransactions(transactions: Transaction[]): TransactionStats {
  if (transactions.length === 0) {
    return {
      totalVolume: 0,
      avgAmount: 0,
      rewardDistribution: { masternodes: 0, staking: 0, devFund: 0 },
      timeRange: { start: Date.now() - 3600000, end: Date.now() },
      volumeHistory: []
    };
  }

  const sortedTxs = [...transactions].sort((a, b) => a.timestamp - b.timestamp);
  const startTime = sortedTxs[0].timestamp * 1000;
  const endTime = sortedTxs[sortedTxs.length - 1].timestamp * 1000;

  const rewardTxs = transactions.filter(tx => tx.recipients === 3 && tx.amount === 100);
  const regularTxs = transactions.filter(tx => tx.recipients !== 3 || tx.amount !== 100);
  
  const totalRewards = rewardTxs.length * 100;
  const masternodeRewards = totalRewards * 0.8;
  const stakingRewards = totalRewards * 0.1;
  const devFundRewards = totalRewards * 0.1;
  
  const totalVolume = regularTxs.reduce((sum, tx) => sum + tx.amount, 0);
  const avgAmount = regularTxs.length > 0 ? totalVolume / regularTxs.length : 0;

  // Create time slots for the chart
  const slots = createTimeSlots(startTime, endTime, 20); // 20 time slots
  
  // Fill slots with transaction volumes
  const volumeHistory = slots.map(slot => {
    const slotTxs = transactions.filter(tx => 
      tx.timestamp * 1000 >= slot.start && 
      tx.timestamp * 1000 < slot.end
    );
    return {
      timestamp: slot.start,
      value: slotTxs.reduce((sum, tx) => sum + tx.amount, 0)
    };
  });

  return {
    totalVolume,
    avgAmount,
    rewardDistribution: {
      masternodes: masternodeRewards,
      staking: stakingRewards,
      devFund: devFundRewards
    },
    timeRange: {
      start: startTime,
      end: endTime
    },
    volumeHistory
  };
}