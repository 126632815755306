import React from 'react';
import { Coins } from 'lucide-react';
import SupplyOverview from './SupplyOverview';
import SupplyDistribution from './SupplyDistribution';

interface SupplyWidgetProps {
  supply: number;
  masternodes: number;
  circulating: number;
}

export default function SupplyWidget({ supply, masternodes, circulating }: SupplyWidgetProps) {
  return (
    <div className="h-full col-span-1 md:col-span-2 min-w-[300px] bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center space-x-2 mb-4 md:mb-6">
        <Coins className="h-5 w-5 md:h-6 md:w-6 text-green-500" />
        <h3 className="text-lg md:text-xl font-semibold">TWINS Supply</h3>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 h-[calc(100%-3.5rem)]">
        <SupplyOverview 
          total={supply}
          circulating={circulating}
        />
        <SupplyDistribution 
          masternodes={masternodes}
          total={supply}
        />
      </div>
    </div>
  );
}