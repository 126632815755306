import { API_CONFIG } from './config';
import { ENDPOINTS } from './endpoints';
import { fetchWithTimeout } from './fetch';
import type { DistributionData } from '../types/distribution';

export async function fetchDistribution(): Promise<DistributionData> {
  try {
    const response = await fetchWithTimeout(ENDPOINTS.EXTENDED.DISTRIBUTION);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.warn('Distribution fetch error:', error);
    return API_CONFIG.FALLBACK.DISTRIBUTION;
  }
}