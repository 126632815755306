import React from 'react';
import { Download } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface WalletOptionProps {
  icon: LucideIcon;
  title: string;
  options: Array<{
    label: string;
    url: string;
  }>;
}

export default function WalletOption({ icon: Icon, title, options }: WalletOptionProps) {
  return (
    <div className="bg-slate-900/50 rounded-xl p-6 border border-green-500/10">
      <div className="flex items-center gap-3 mb-4">
        <Icon className="h-6 w-6 text-green-500" />
        <span className="text-lg font-semibold">{title}</span>
      </div>

      <div className="space-y-2">
        {options.map((option, index) => (
          <a
            key={index}
            href={option.url}
            className="flex items-center justify-between p-3 rounded-lg bg-slate-800/50 hover:bg-slate-800 transition-colors group"
          >
            <span className="text-sm font-mono text-gray-300">{option.label}</span>
            <Download className="h-4 w-4 text-green-500 group-hover:scale-110 transition-transform" />
          </a>
        ))}
      </div>
    </div>
  );
}