import { API_CONFIG } from './config';
import { fetchWithTimeout } from './fetch';

export interface Masternode {
  rank: number;
  network: string;
  txhash: string;
  outidx: number;
  status: string;
  addr: string;
  version: number;
  lastseen: number;
  activetime: number;
}

export async function fetchMasternodeList(): Promise<Masternode[]> {
  try {
    const response = await fetchWithTimeout('/ext/getmasternodelist');
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return await response.json();
  } catch (error) {
    console.warn('Failed to fetch masternode list:', error);
    return [];
  }
}

export async function fetchNodeBalance(address: string): Promise<number> {
  try {
    const response = await fetchWithTimeout(`/ext/getbalance/${address}`);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const balance = await response.json();
    return Number(balance);
  } catch (error) {
    console.warn(`Failed to fetch balance for ${address}:`, error);
    return 0;
  }
}