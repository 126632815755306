import { useState, useEffect } from 'react';
import type { NetworkStatsData, Block, TransactionData } from '../types/dashboard';

export function useDashboardData() {
  const [data, setData] = useState({
    stats: {
      hashrate: '128.5 TH/s',
      hashrateChange: '+12.5%',
      blockHeight: 2859261,
      blocksLastHour: 60,
      activeNodes: 3642,
      nodeChange: '+52',
      marketCap: 1250000000,
      marketCapChange: '+5.2%'
    } as NetworkStatsData,
    
    blocks: Array.from({ length: 5 }, (_, i) => ({
      height: 2859261 - i,
      timestamp: Date.now() - i * 60000,
      transactions: Math.floor(Math.random() * 1000) + 500,
      size: Math.floor(Math.random() * 900) + 100
    })) as Block[],
    
    transactions: {
      volume24h: 25000000,
      avgFee: 0.001,
      history: Array.from({ length: 24 }, () => 
        Math.floor(Math.random() * 80) + 20
      )
    } as TransactionData
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setData(prev => ({
        ...prev,
        stats: {
          ...prev.stats,
          blockHeight: prev.stats.blockHeight + 1
        },
        blocks: [
          {
            height: prev.stats.blockHeight + 1,
            timestamp: Date.now(),
            transactions: Math.floor(Math.random() * 1000) + 500,
            size: Math.floor(Math.random() * 900) + 100
          },
          ...prev.blocks.slice(0, -1)
        ]
      }));
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  return data;
}