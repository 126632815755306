export function scrollToTop(): void {
  window.scrollTo({ 
    top: 0, 
    behavior: 'smooth' 
  });
}

export function scrollToWallets(): void {
  const walletSection = document.getElementById('wallet-downloads');
  if (walletSection) {
    const headerOffset = 80; // Account for fixed header
    const elementPosition = walletSection.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}