export const MASTERNODE_TIERS = [
  { value: 1_000_000, label: '1M' },
  { value: 5_000_000, label: '5M' },
  { value: 20_000_000, label: '20M' },
  { value: 100_000_000, label: '100M' }
] as const;

export function getTierSummary(nodes: { tier?: number }[]) {
  const summary = MASTERNODE_TIERS.map(tier => ({
    ...tier,
    count: nodes.filter(node => node.tier === tier.value).length
  }));

  return summary;
}