import React, { useState } from 'react';
import { ChevronDown, ChevronUp, ExternalLink } from 'lucide-react';
import type { ApiEndpoint } from '../../types/api';

export default function EndpointCard({ name, description, endpoint, example, params }: ApiEndpoint) {
  const [isExpanded, setIsExpanded] = useState(false);
  const baseUrl = 'explorer.win.win';

  return (
    <div className="bg-slate-800 rounded-lg border border-green-500/10">
      <div 
        className="p-4 cursor-pointer hover:bg-slate-700/50 transition-colors"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-lg font-semibold mb-1">{name}</h3>
            <p className="text-gray-400 text-sm">{description}</p>
          </div>
          {isExpanded ? (
            <ChevronUp className="h-5 w-5 text-gray-400" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>
      
      {isExpanded && (
        <div className="border-t border-green-500/10 p-4 space-y-4">
          <div>
            <div className="text-sm text-gray-400 mb-1">Endpoint</div>
            <div className="font-mono text-green-400 text-sm break-all">
              {baseUrl}{endpoint}
            </div>
          </div>
          
          {params && params.length > 0 && (
            <div>
              <div className="text-sm text-gray-400 mb-2">Parameters</div>
              <div className="space-y-2">
                {params.map((param, index) => (
                  <div key={index} className="text-sm">
                    <span className="font-mono text-green-400">{param.name}</span>
                    <span className="text-gray-400 mx-2">-</span>
                    <span className="text-gray-300">{param.description}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {example && (
            <div>
              <div className="text-sm text-gray-400 mb-1">Example</div>
              <a 
                href={`https://${baseUrl}${example}`}
                target="_blank"
                rel="noopener noreferrer"
                className="font-mono text-sm text-blue-400 hover:text-blue-300 break-all flex items-center gap-1"
              >
                {baseUrl}{example}
                <ExternalLink className="h-3 w-3" />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}