import React from 'react';
import { motion } from 'framer-motion';

interface WhitepaperSectionProps {
  title: string;
  content: string | string[];
}

export default function WhitepaperSection({ title, content }: WhitepaperSectionProps) {
  return (
    <motion.section 
      className="prose prose-invert max-w-none"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      {Array.isArray(content) ? (
        <div className="space-y-4">
          {content.map((paragraph, index) => (
            <p key={index} className="text-gray-300 leading-relaxed">
              {paragraph}
            </p>
          ))}
        </div>
      ) : (
        <p className="text-gray-300 leading-relaxed">{content}</p>
      )}
    </motion.section>
  );
}