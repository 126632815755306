import type { MasternodeWithStats } from '../../hooks/useMasternodes';

export function calculateROI(node: MasternodeWithStats): {
  daily: number;
  monthly: number;
  yearly: number;
} {
  const dailyRewards = node.rewardStats?.rewardsPerDay || 0;
  const collateral = node.tier || 0;

  if (!collateral) return { daily: 0, monthly: 0, yearly: 0 };

  const daily = (dailyRewards / collateral) * 100;
  const monthly = daily * 30.44; // Average days in a month
  const yearly = daily * 365.25; // Account for leap years

  return { daily, monthly, yearly };
}

export function formatROI(value: number): string {
  return `${value.toFixed(3)}%`;
}