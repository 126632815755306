import React from 'react';
import { Terminal } from 'lucide-react';

export default function ApiHeader() {
  return (
    <div className="mb-16">
      <div className="flex items-center justify-center gap-3 mb-6">
        <Terminal className="h-10 w-10 text-green-500" />
        <h1 className="text-4xl font-bold">TWINS API Documentation</h1>
      </div>
      <p className="text-gray-400 text-center max-w-2xl mx-auto">
        Access the TWINS blockchain data through our comprehensive API endpoints. 
        All endpoints are accessible via HTTPS GET requests and return JSON responses.
      </p>
    </div>
  );
}