import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhitepaperContent from '../components/whitepaper/WhitepaperContent';

export default function Whitepaper() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
      <Header />
      <div className="container mx-auto px-6 py-32">
        <WhitepaperContent />
      </div>
      <Footer />
    </div>
  );
}