import React from 'react';

interface TwinsAmountProps {
  amount: number;
  className?: string;
  iconSize?: number;
  showDecimals?: boolean;
}

export default function TwinsAmount({ 
  amount, 
  className = '', 
  iconSize = 16,
  showDecimals = false
}: TwinsAmountProps) {
  const formatAmount = (num: number) => {
    if (num >= 1_000_000_000) {
      const billions = num / 1_000_000_000;
      return showDecimals ? 
        `${billions.toFixed(2)}B` : 
        `${Math.floor(billions)}B`;
    }
    if (num >= 1_000_000) {
      const millions = num / 1_000_000;
      return showDecimals ? 
        `${millions.toFixed(2)}M` : 
        `${Math.floor(millions)}M`;
    }
    return num.toLocaleString();
  };

  return (
    <div className={`inline-flex items-center gap-1 font-mono ${className}`}>
      {formatAmount(amount)}
      <svg 
        viewBox="0 0 32 32" 
        width={iconSize} 
        height={iconSize}
        className="text-[#5CB54B]"
      >
        <g transform="translate(3.2, 9.6)">
          <path 
            d="M0.711181985,0 L7.8278936,-6.66133815e-16 C8.23905111,-1.10684345e-15 8.62165857,0.210243283 8.84210288,0.557309276 L12.934277,7 L8.8341032,13.4552854 C8.71551098,13.6419961 8.468014,13.6972171 8.28130323,13.5786249 C8.22924919,13.545562 8.18553316,13.5009252 8.1535622,13.4481935 L0.36871075,0.608138306 C0.254035263,0.418996665 0.314402009,0.172704221 0.503543649,0.0580287343 C0.566151172,0.0200701539 0.637966169,8.46116796e-16 0.711181985,8.32667268e-16 Z" 
            fill="currentColor"
          />
          <path 
            d="M13.645459,0 L20.7621706,-6.66133815e-16 C21.1733282,-1.10684345e-15 21.5559356,0.210243283 21.7763799,0.557309276 L25.8685541,7 L21.7683802,13.4552854 C21.649788,13.6419961 21.402291,13.6972171 21.2155803,13.5786249 C21.1635262,13.545562 21.1198102,13.5009252 21.0878393,13.4481935 L13.3029878,0.608138306 C13.1883123,0.418996665 13.2486791,0.172704221 13.4378207,0.0580287343 C13.5004282,0.0200701539 13.5722432,8.46116796e-16 13.645459,8.32667268e-16 Z" 
            fill="#E93F33" 
            transform="translate(19.401416, 7.000000) scale(-1, 1) translate(-19.401416, -7.000000)"
          />
        </g>
      </svg>
    </div>
  );
}