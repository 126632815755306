import React from 'react';
import { FileText } from 'lucide-react';

export default function WhitepaperHeader() {
  return (
    <div className="text-center mb-16">
      <div className="flex items-center justify-center gap-3 mb-6">
        <FileText className="h-10 w-10 text-green-500" />
        <h1 className="text-4xl font-bold">TWINS Whitepaper</h1>
      </div>
      <div className="max-w-2xl mx-auto">
        <p className="text-xl text-gray-300 mb-4">
          A Green, Fast, Scalable, and Decentralized P2P Value Exchange Platform with Volatility Resistance
        </p>
        <p className="text-gray-400">
          Technical overview of the TWINS blockchain network, its innovative architecture, 
          and dynamic consensus mechanism designed for stability and sustainability.
        </p>
      </div>
    </div>
  );
}