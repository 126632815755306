import React from 'react';
import { Menu, X } from 'lucide-react';
import { useMobileMenu } from '../../hooks/useMobileMenu';

export default function MobileMenuButton() {
  const { isOpen, toggle } = useMobileMenu();

  return (
    <button
      onClick={toggle}
      className="text-gray-400 hover:text-white transition-colors"
      aria-label="Toggle menu"
    >
      {isOpen ? (
        <X className="h-6 w-6" />
      ) : (
        <Menu className="h-6 w-6" />
      )}
    </button>
  );
}