import React from 'react';
import { Server, Coins, Wallet } from 'lucide-react';
import { formatAmount } from '../../../utils/format/number';

interface TransactionDistributionProps {
  distribution: {
    masternodes: number;
    staking: number;
    devFund: number;
  };
}

export default function TransactionDistribution({ distribution }: TransactionDistributionProps) {
  const items = [
    { label: 'Masternodes', value: distribution.masternodes, icon: Server, percent: 80 },
    { label: 'Staking', value: distribution.staking, icon: Coins, percent: 10 },
    { label: 'Dev Fund', value: distribution.devFund, icon: Wallet, percent: 10 }
  ];

  return (
    <div className="space-y-4">
      {items.map(({ label, value, icon: Icon, percent }) => (
        <div key={label} className="p-4 bg-slate-900/50 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <Icon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-gray-400">{label}</span>
            </div>
            <span className="text-sm text-green-400">{percent}%</span>
          </div>
          <div className="h-2 bg-slate-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-green-500 rounded-full transition-all duration-500"
              style={{ width: `${percent}%` }}
            />
          </div>
          <div className="mt-2 text-right">
            <span className="text-xs text-gray-400 font-mono">
              {formatAmount(value)} TWINS
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}