import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import BlockchainDashboard from './components/dashboard/BlockchainDashboard';
import NetworkSpecs from './components/specs/NetworkSpecs';
import WalletDownloads from './components/wallets/WalletDownloads';
import Footer from './components/Footer';
import ApiDocs from './pages/ApiDocs';
import Privacy from './pages/Privacy';
import Whitepaper from './pages/Whitepaper';
import DevFund from './pages/DevFund';
import Masternodes from './pages/Masternodes';
import { NetworkProvider } from './contexts/NetworkContext';
import { ReleaseProvider } from './contexts/ReleaseContext';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NetworkProvider>
        <ReleaseProvider>
          <Routes>
            <Route path="/api" element={<ApiDocs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/whitepaper" element={<Whitepaper />} />
            <Route path="/devfund" element={<DevFund />} />
            <Route path="/masternodes" element={<Masternodes />} />
            <Route path="/" element={
              <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
                <Header />
                <Hero />
                <BlockchainDashboard />
                <NetworkSpecs />
                <WalletDownloads />
                <Footer />
              </div>
            } />
          </Routes>
        </ReleaseProvider>
      </NetworkProvider>
    </BrowserRouter>
  );
}