import { createContext, useContext, useEffect, useState } from 'react';
import { networkClient } from '../api/network/client';
import type { NetworkSummary } from '../api/types';
import { API_CONFIG } from '../api/config';

interface NetworkContextType {
  data: NetworkSummary;
  loading: boolean;
  error: Error | null;
}

const NetworkContext = createContext<NetworkContextType | undefined>(undefined);

export function NetworkProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = useState<NetworkSummary>({
    difficulty: API_CONFIG.FALLBACK.DIFFICULTY,
    difficultyHybrid: "",
    supply: API_CONFIG.FALLBACK.MONEY_SUPPLY,
    hashrate: API_CONFIG.FALLBACK.HASHRATE.toString(),
    lastPrice: API_CONFIG.FALLBACK.PRICE.BTC,
    connections: API_CONFIG.FALLBACK.CONNECTIONS,
    masternodeCountOnline: API_CONFIG.FALLBACK.MASTERNODES.ONLINE,
    masternodeCountOffline: API_CONFIG.FALLBACK.MASTERNODES.OFFLINE,
    blockcount: API_CONFIG.FALLBACK.BLOCK_HEIGHT
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        const summary = await networkClient.getSummary();
        if (mounted) {
          setData(summary);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch network data'));
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchData();
    const interval = setInterval(fetchData, API_CONFIG.REFRESH_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  return (
    <NetworkContext.Provider value={{ data, loading, error }}>
      {children}
    </NetworkContext.Provider>
  );
}

export function useNetwork() {
  const context = useContext(NetworkContext);
  if (context === undefined) {
    throw new Error('useNetwork must be used within a NetworkProvider');
  }
  return context;
}