import { API_CONFIG } from '../config';
import { fetchWithTimeout } from '../fetch';

export interface NodeTransaction {
  timestamp: number;
  txid: string;
  sent: number;
  received: number;
  balance: number;
}

export interface RewardStats {
  lastPaid: number | null;
  rewardsPerDay: number;
  averageInterval: number;
}

export async function fetchNodeTransactions(address: string): Promise<NodeTransaction[]> {
  try {
    const response = await fetchWithTimeout(`/ext/getaddresstxs/${address}/0/100`);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return await response.json();
  } catch (error) {
    console.warn(`Failed to fetch transactions for ${address}:`, error);
    return [];
  }
}

export function analyzeRewards(transactions: NodeTransaction[]): RewardStats {
  // Consider both sent and received 80 TWINS transactions as rewards
  const rewardTxs = transactions
    .filter(tx => Math.abs(tx.sent - tx.received) === 80)
    .map(tx => ({
      ...tx,
      timestamp: tx.timestamp,
      amount: 80 // Normalize to always show 80 TWINS reward
    }))
    .sort((a, b) => b.timestamp - a.timestamp);

  if (rewardTxs.length === 0) {
    return {
      lastPaid: null,
      rewardsPerDay: 0,
      averageInterval: 0
    };
  }

  const now = Math.floor(Date.now() / 1000);
  const DAY_IN_SECONDS = 24 * 60 * 60;

  // If only one reward, calculate based on time since that reward
  if (rewardTxs.length === 1) {
    const timeSinceReward = now - rewardTxs[0].timestamp;
    const daysElapsed = Math.max(timeSinceReward / DAY_IN_SECONDS, 1); // Prevent division by small numbers
    
    return {
      lastPaid: rewardTxs[0].timestamp,
      rewardsPerDay: 80 / daysElapsed,
      averageInterval: timeSinceReward
    };
  }

  // Calculate intervals between rewards
  const intervals = rewardTxs.slice(0, -1).map((tx, i) => 
    tx.timestamp - rewardTxs[i + 1].timestamp
  );

  // Calculate average interval
  const averageInterval = intervals.reduce((sum, interval) => sum + interval, 0) / intervals.length;

  // Calculate rewards per day based on average interval
  const rewardsPerDay = (DAY_IN_SECONDS / Math.max(averageInterval, 1)) * 80;

  return {
    lastPaid: rewardTxs[0].timestamp,
    rewardsPerDay,
    averageInterval
  };
}