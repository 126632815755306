import { useState, useEffect } from 'react';
import { networkClient } from '../../api/network/client';
import type { NetworkSummary } from '../../api/types';
import { API_CONFIG } from '../../api/config';

export function useNetworkSummary() {
  const [data, setData] = useState<NetworkSummary>({
    difficulty: API_CONFIG.FALLBACK.DIFFICULTY,
    difficultyHybrid: "",
    supply: API_CONFIG.FALLBACK.MONEY_SUPPLY,
    hashrate: API_CONFIG.FALLBACK.HASHRATE.toString(),
    lastPrice: API_CONFIG.FALLBACK.PRICE.BTC,
    connections: API_CONFIG.FALLBACK.CONNECTIONS,
    masternodeCountOnline: API_CONFIG.FALLBACK.MASTERNODES.ONLINE,
    masternodeCountOffline: API_CONFIG.FALLBACK.MASTERNODES.OFFLINE,
    blockcount: API_CONFIG.FALLBACK.BLOCK_HEIGHT
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    
    async function fetchData() {
      if (!mounted) return;
      
      const summary = await networkClient.getSummary();
      if (mounted) {
        setData(summary);
        setLoading(false);
      }
    }

    fetchData();
    const interval = setInterval(fetchData, API_CONFIG.REFRESH_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  return { data, loading };
}