import React from 'react';
import { Monitor, Apple, MonitorDown } from 'lucide-react';
import WalletOption from './WalletOption';
import WalletHeader from './WalletHeader';
import { useRelease } from '../../contexts/ReleaseContext';

export default function WalletDownloads() {
  const { release, loading } = useRelease();

  if (loading) {
    return (
      <section id="wallet-downloads" className="py-20 px-6 bg-slate-800/50">
        <div className="container mx-auto">
          <WalletHeader />
          <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="bg-slate-900/50 rounded-xl p-6 border border-green-500/10 animate-pulse">
                <div className="h-32"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (!release) return null;

  return (
    <section id="wallet-downloads" className="py-20 px-6 bg-slate-800/50">
      <div className="container mx-auto">
        <WalletHeader />
        
        <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
          <WalletOption
            icon={Monitor}
            title="Windows"
            options={[
              release.assets.windows.x32 && {
                label: 'Windows 32-bit',
                url: release.assets.windows.x32
              },
              release.assets.windows.x64 && {
                label: 'Windows 64-bit',
                url: release.assets.windows.x64
              }
            ].filter(Boolean)}
          />

          <WalletOption
            icon={Apple}
            title="Mac OS"
            options={[
              release.assets.mac && {
                label: 'Mac OS 10.13+',
                url: release.assets.mac
              }
            ].filter(Boolean)}
          />

          <WalletOption
            icon={MonitorDown}
            title="Linux"
            options={[
              release.assets.linux.aarch64 && {
                label: 'ARM64',
                url: release.assets.linux.aarch64
              },
              release.assets.linux.arm && {
                label: 'ARM',
                url: release.assets.linux.arm
              },
              release.assets.linux.x86 && {
                label: 'x86',
                url: release.assets.linux.x86
              },
              release.assets.linux.x64 && {
                label: 'x64',
                url: release.assets.linux.x64
              }
            ].filter(Boolean)}
          />
        </div>
      </div>
    </section>
  );
}