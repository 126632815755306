import React from 'react';
import { useDevFundBalance } from '../../hooks/useDevFundBalance';
import TwinsAmount from '../icons/TwinsAmount';
import { formatNumber } from '../../utils/format/number';

export default function DevFundBalance() {
  const { balance, loading, error } = useDevFundBalance();

  return (
    <div className="bg-slate-800/50 rounded-xl p-6 border border-green-500/10">
      <h2 className="text-xl font-semibold mb-4">Current Balance</h2>
      <div className="flex items-center justify-center py-8">
        {loading ? (
          <div className="animate-pulse">
            <div className="h-8 w-48 bg-slate-700 rounded" />
          </div>
        ) : error ? (
          <div className="text-red-400">Failed to load balance</div>
        ) : (
          <TwinsAmount 
            amount={formatNumber(balance)}
            size="lg"
            className="text-3xl text-green-400"
          />
        )}
      </div>
    </div>
  );
}