import React from 'react';
import BrandLogo from './brand/BrandLogo';
import MainNav from './navigation/MainNav';
import MobileMenuButton from './navigation/MobileMenuButton';
import { useScrollTimeout } from '../hooks/useScrollTimeout';

export default function Header() {
  const isVisible = useScrollTimeout();

  return (
    <header 
      className={`fixed top-0 left-0 right-0 transition-all duration-500 z-50 border-b border-white/10
        ${isVisible 
          ? 'translate-y-0 opacity-100' 
          : '-translate-y-full opacity-0'}`}
      style={{
        background: 'linear-gradient(to bottom, rgba(15, 23, 42, 0.9), rgba(15, 23, 42, 0.8))',
        backdropFilter: 'blur(8px)',
      }}
    >
      <div className="container mx-auto px-6 py-4">
        <div className="flex items-center gap-4">
          <div className="md:hidden">
            <MobileMenuButton />
          </div>
          <BrandLogo size="default" showText={false} />
          <MainNav />
        </div>
      </div>
    </header>
  );
}