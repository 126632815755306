import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatAmount } from '../../../utils/format/number';
import { formatTime } from '../../../utils/time';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface TransactionChartProps {
  volumeHistory: Array<{
    timestamp: number;
    value: number;
  }>;
}

export default function TransactionChart({ volumeHistory }: TransactionChartProps) {
  const data = {
    labels: volumeHistory.map(point => formatTime(point.timestamp)),
    datasets: [
      {
        label: 'Volume',
        data: volumeHistory.map(point => point.value),
        borderColor: '#5CB54B',
        backgroundColor: '#5CB54B20',
        fill: true,
        tension: 0.4
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(15, 23, 42, 0.9)',
        titleColor: '#94a3b8',
        bodyColor: '#ffffff',
        callbacks: {
          label: (context: any) => `Volume: ${formatAmount(context.raw)} TWINS`
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: '#64748b',
          maxTicksLimit: 6,
          callback: (value: any) => value
        }
      },
      y: {
        grid: {
          color: '#1e293b'
        },
        ticks: {
          color: '#64748b',
          callback: (value: number) => formatAmount(value)
        }
      }
    }
  };

  return (
    <div className="h-[200px]">
      <Line data={data} options={options} />
    </div>
  );
}