import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ApiHeader from '../components/api/ApiHeader';
import ApiSection from '../components/api/ApiSection';
import { CORE_API_ENDPOINTS, EXTENDED_API_ENDPOINTS, LINKING_ENDPOINTS } from '../constants/api';

export default function ApiDocs() {
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
      <Header />
      <div className="container mx-auto px-6 py-32">
        <ApiHeader />
        
        <div className="space-y-16">
          <ApiSection
            title="Core API"
            description="Direct blockchain data from TWINS blockchain explorer"
            endpoints={CORE_API_ENDPOINTS}
          />
          
          <ApiSection
            title="Extended API"
            description="Data from local indexes and additional services"
            endpoints={EXTENDED_API_ENDPOINTS}
          />
          
          <ApiSection
            title="Linking API"
            description="Direct links to explorer pages"
            endpoints={LINKING_ENDPOINTS}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}