import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MasternodeStats from '../components/masternodes/MasternodeStats';
import MasternodeList from '../components/masternodes/MasternodeList';
import { useMasternodes } from '../hooks/useMasternodes';

export default function Masternodes() {
  const { nodes, loading, error } = useMasternodes();

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
      <Header />
      <div className="container mx-auto px-6 py-32">
        <div className="mb-16">
          <h1 className="text-4xl font-bold mb-4">TWINS Masternodes</h1>
          <p className="text-gray-400 max-w-3xl">
            Masternodes are full nodes that secure the TWINS network, validate transactions, 
            and earn rewards. Operators can run nodes with collateral tiers of 1M, 5M, 20M, 
            or 100M TWINS.
          </p>
        </div>

        <div className="space-y-8">
          <MasternodeStats nodes={nodes} loading={loading} />
          <MasternodeList nodes={nodes} loading={loading} error={error} />
        </div>
      </div>
      <Footer />
    </div>
  );
}