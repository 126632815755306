import type { SizeConfig } from '../types/brand';

export const LOGO_SIZES: Record<'default' | 'large', SizeConfig> = {
  default: {
    container: 'h-8',
    logo: 'h-8 w-8',
    text: 'text-2xl'
  },
  large: {
    container: 'h-[50px]',
    logo: 'h-[50px] w-[50px]',
    text: 'text-3xl'
  }
} as const;