import React from 'react';
import DevFundHeader from './DevFundHeader';
import DevFundBalance from './DevFundBalance';
import ContributionsList from './ContributionsList';

export default function DevFundContent() {
  return (
    <div className="max-w-4xl mx-auto">
      <DevFundHeader />
      <div className="space-y-12">
        <DevFundBalance />
        <ContributionsList />
      </div>
    </div>
  );
}