import React from 'react';
import { Link } from 'react-router-dom';
import TwinsLogo from '../icons/TwinsLogo';
import { LOGO_SIZES } from '../../constants/sizes';
import type { LogoProps } from '../../types/brand';

export default function BrandLogo({ 
  size = 'default', 
  className = '',
  showText = true
}: LogoProps) {
  const sizeConfig = LOGO_SIZES[size];

  return (
    <div className={`flex items-center ${className}`}>
      <Link 
        to="/"
        className={`flex items-center gap-2 hover:opacity-80 transition-opacity`}
      >
        <div className={sizeConfig.logo}>
          <TwinsLogo className="h-full w-full" />
        </div>
        {showText && (
          <span className={`${sizeConfig.text} font-bold leading-none`}>
            TWINS
          </span>
        )}
      </Link>
    </div>
  );
}