import React from 'react';
import PrivacyHeader from './PrivacyHeader';
import PrivacySection from './PrivacySection';
import { PRIVACY_SECTIONS } from '../../constants/privacy';

export default function PrivacyContent() {
  return (
    <div className="max-w-4xl mx-auto">
      <PrivacyHeader />
      <div className="space-y-12">
        {PRIVACY_SECTIONS.map((section, index) => (
          <PrivacySection 
            key={index}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
    </div>
  );
}