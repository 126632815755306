import React from 'react';
import { getTierSummary } from '../../utils/masternodes/tiers';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';
import { motion } from 'framer-motion';

const TIER_COLORS = [
  'bg-green-500', // 1M
  'bg-green-400', // 5M
  'bg-green-300', // 20M
  'bg-green-200'  // 100M
] as const;

interface TierSummaryProps {
  nodes: MasternodeWithStats[];
}

export default function TierSummary({ nodes }: TierSummaryProps) {
  const summary = getTierSummary(nodes);
  const totalNodes = summary.reduce((sum, tier) => sum + tier.count, 0);
  
  return (
    <div className="space-y-2">
      {summary.map((tier, index) => {
        const percentage = (tier.count / totalNodes) * 100;
        const colorClass = TIER_COLORS[index];
        
        return (
          <div key={tier.value} className="space-y-1">
            <div className="flex items-center justify-between text-xs">
              <div className="flex items-center gap-2">
                <div className={`w-2 h-2 rounded-full ${colorClass}`} />
                <span className="text-gray-400">{tier.label}</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="font-mono text-green-400">{tier.count}</span>
                <span className="text-gray-500 w-12 text-right">{percentage.toFixed(1)}%</span>
              </div>
            </div>
            
            <motion.div 
              className="h-1 bg-slate-700 rounded-full overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.1 }}
            >
              <motion.div 
                className={`h-full ${colorClass} rounded-full`}
                initial={{ width: 0 }}
                animate={{ width: `${percentage}%` }}
                transition={{ 
                  duration: 0.8,
                  delay: index * 0.1,
                  ease: "easeOut"
                }}
              />
            </motion.div>
          </div>
        );
      })}
    </div>
  );
}