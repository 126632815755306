import React from 'react';
import MatrixBackground from '../blockchain/MatrixBackground';
import NetworkStats from './NetworkStats';
import { useDashboardData } from '../../hooks/useDashboardData';

export default function BlockchainDashboard() {
  const { stats } = useDashboardData();
  
  return (
    <section className="min-h-screen relative bg-slate-900/95">
      <MatrixBackground />
      <div className="relative container mx-auto px-6 py-20">
        <div className="grid gap-8">
          <NetworkStats data={stats} />
        </div>
      </div>
    </section>
  );
}