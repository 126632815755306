import { useState, useEffect } from 'react';
import { fetchDistribution } from '../api/distribution';
import { API_CONFIG } from '../api/config';
import type { DistributionData } from '../types/distribution';

export function useDistribution() {
  const [data, setData] = useState<DistributionData>(API_CONFIG.FALLBACK.DISTRIBUTION);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;
    let retryCount = 0;
    const MAX_RETRIES = 3;

    async function fetchData() {
      if (!mounted) return;
      
      try {
        const distribution = await fetchDistribution();
        if (mounted) {
          setData(distribution);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch distribution'));
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            // Exponential backoff
            setTimeout(fetchData, Math.pow(2, retryCount) * 1000);
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchData();
    const interval = setInterval(fetchData, API_CONFIG.REFRESH_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  return { data, loading, error };
}