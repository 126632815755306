import React from 'react';
import { Coins } from 'lucide-react';

export default function DevFundHeader() {
  return (
    <div className="text-center mb-16">
      <div className="flex items-center justify-center gap-3 mb-6">
        <Coins className="h-10 w-10 text-green-500" />
        <h1 className="text-4xl font-bold">TWINS Development Fund</h1>
      </div>
      <p className="text-gray-300 max-w-2xl mx-auto">
        The TWINS Development Fund receives 10% of block rewards to support network growth, 
        development, and community initiatives. Join us in building the future of peer-to-peer 
        value exchange.
      </p>
    </div>
  );
}