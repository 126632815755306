import { API_CONFIG } from '../config';
import { fetchWithTimeout } from '../fetch';
import { ENDPOINTS } from '../endpoints';
import type { NetworkSummary } from '../types';

class NetworkClient {
  private static instance: NetworkClient;
  
  private constructor() {}
  
  static getInstance(): NetworkClient {
    if (!this.instance) {
      this.instance = new NetworkClient();
    }
    return this.instance;
  }

  async getSummary(): Promise<NetworkSummary> {
    try {
      const response = await fetchWithTimeout(ENDPOINTS.EXTENDED.SUMMARY);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      // Return fallback data instead of throwing
      return {
        difficulty: API_CONFIG.FALLBACK.DIFFICULTY,
        difficultyHybrid: "",
        supply: API_CONFIG.FALLBACK.MONEY_SUPPLY,
        hashrate: API_CONFIG.FALLBACK.HASHRATE.toString(),
        lastPrice: API_CONFIG.FALLBACK.PRICE.BTC,
        connections: API_CONFIG.FALLBACK.CONNECTIONS,
        masternodeCountOnline: API_CONFIG.FALLBACK.MASTERNODES.ONLINE,
        masternodeCountOffline: API_CONFIG.FALLBACK.MASTERNODES.OFFLINE,
        blockcount: API_CONFIG.FALLBACK.BLOCK_HEIGHT
      };
    }
  }
}

export const networkClient = NetworkClient.getInstance();