import React from 'react';
import SpecsHeader from './SpecsHeader';
import SpecsGrid from './SpecsGrid';
import { NETWORK_SPECS } from './constants';

export default function NetworkSpecs() {
  return (
    <section className="relative py-20">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[radial-gradient(#1e293b_1px,transparent_1px)] [background-size:16px_16px] opacity-50" />
      
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-slate-900/95 via-slate-900/98 to-slate-900/95" />
      
      {/* Accent Lines */}
      <div className="absolute inset-0">
        <div className="h-px w-full bg-gradient-to-r from-transparent via-green-500/20 to-transparent" />
        <div className="absolute bottom-0 h-px w-full bg-gradient-to-r from-transparent via-green-500/20 to-transparent" />
      </div>

      {/* Content */}
      <div className="relative container mx-auto px-6">
        <SpecsHeader />
        <SpecsGrid specs={NETWORK_SPECS} />
      </div>
    </section>
  );
}