import React from 'react';
import { Link } from 'react-router-dom';
import { Coins, Github, Twitter } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-slate-900 py-12 px-6">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-4 gap-12">
          <div>
            <div className="flex items-center space-x-2 mb-6">
              <Coins className="h-8 w-8 text-green-500" />
              <span className="text-2xl font-bold">TWINS</span>
            </div>
            <p className="text-gray-400">
              Open source, peer-to-peer cryptocurrency
            </p>
          </div>
          <div>
            <h3 className="font-semibold mb-4">Resources</h3>
            <ul className="space-y-2 text-gray-400">
              <li><Link to="/whitepaper" className="hover:text-green-400">Whitepaper</Link></li>
              <li><Link to="/api" className="hover:text-green-400">API</Link></li>
              <li><Link to="/masternodes" className="hover:text-green-400">Masternodes</Link></li>
              <li><Link to="/privacy" className="hover:text-green-400">Privacy Policy</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-4">Community</h3>
            <ul className="space-y-2 text-gray-400">
              <li><Link to="/devfund" className="hover:text-green-400">Dev Fund</Link></li>
              <li>
                <a 
                  href="https://discord.gg/newcapital" 
                  className="hover:text-green-400"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord
                </a>
              </li>
              <li>
                <a 
                  href="https://twitter.com/TWINS_Coin" 
                  className="hover:text-green-400"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-4">Connect</h3>
            <div className="flex space-x-4">
              <a 
                href="https://github.com/NewCapital/TWINS-Core" 
                className="text-gray-400 hover:text-green-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github className="h-6 w-6" />
              </a>
              <a 
                href="https://twitter.com/TWINS_Coin" 
                className="text-gray-400 hover:text-green-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} TWINS. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}