import React from 'react';
import BlockHeightWidget from '../blockchain/BlockHeightWidget';
import SupplyWidget from './supply/SupplyWidget';
import DistributionWidget from './distribution/DistributionWidget';
import TransactionActivityWidget from './transactions/TransactionActivityWidget';
import { useNetworkSummary } from '../../hooks/network/useNetworkSummary';

export default function NetworkStats() {
  const { data, loading } = useNetworkSummary();

  if (loading) {
    return (
      <div className="grid grid-cols-1 gap-4">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10 animate-pulse">
            <div className="h-20"></div>
          </div>
        ))}
      </div>
    );
  }

  const masternodeSupply = data.supply * 0.224; // 22.4% for masternodes
  const circulatingSupply = data.supply - masternodeSupply;

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
      <div className="md:col-span-2">
        <BlockHeightWidget />
      </div>
      <div className="md:col-span-2">
        <SupplyWidget
          supply={data.supply}
          masternodes={masternodeSupply}
          circulating={circulatingSupply}
        />
      </div>
      <div className="md:col-span-2">
        <DistributionWidget />
      </div>
      <div className="md:col-span-2">
        <TransactionActivityWidget />
      </div>
    </div>
  );
}