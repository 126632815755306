import React from 'react';
import { LucideIcon } from 'lucide-react';

interface StatCardProps {
  icon: LucideIcon;
  label: string;
  value: string;
  subValue: React.ReactNode;
  className?: string;
}

export default function StatCard({ 
  icon: Icon, 
  label, 
  value, 
  subValue,
  className = ''
}: StatCardProps) {
  return (
    <div className={`bg-slate-800/50 rounded-lg md:rounded-xl p-4 md:p-6 border border-green-500/10 hover:border-green-500/20 transition-colors ${className}`}>
      <div className="flex items-center gap-2 md:gap-3 mb-3 md:mb-4">
        <Icon className="h-4 w-4 md:h-5 md:w-5 text-green-500" />
        <span className="text-xs md:text-sm text-gray-400 font-medium">{label}</span>
      </div>
      <div className="text-lg md:text-2xl font-mono text-green-400 mb-4">{value}</div>
      <div className="mt-auto">{subValue}</div>
    </div>
  );
}