import { formatDistanceToNow as baseFormatDistance } from '../time';

export function formatDuration(seconds: number): string {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);

  return parts.length > 0 ? parts.join(' ') : '< 1m';
}

export function formatTimestamp(timestamp: number): string {
  return baseFormatDistance(timestamp * 1000);
}

export { formatDistanceToNow } from '../time';