import type { WalletRelease } from './types';

export const FALLBACK_RELEASE: WalletRelease = {
  version: '3.3.9.5',
  publishedAt: '2024-01-01T00:00:00Z',
  assets: {
    windows: {
      x32: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-win32-setup-unsigned.exe',
      x64: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-win64-setup-unsigned.exe'
    },
    mac: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-osx-unsigned.dmg',
    linux: {
      aarch64: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-aarch64-linux-gnu.tar.gz',
      arm: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-arm-linux-gnueabihf.tar.gz',
      x86: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-i686-pc-linux-gnu.tar.gz',
      x64: 'https://github.com/NewCapital/TWINS-Core/releases/download/twins_v3.3.9.5/twins-3.3.9.5-x86_64-linux-gnu.tar.gz'
    }
  }
};