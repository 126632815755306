import React from 'react';
import { Users } from 'lucide-react';
import DistributionBar from './DistributionBar';

interface SupplyDistributionProps {
  masternodes: number;
  total: number;
}

export default function SupplyDistribution({ masternodes, total }: SupplyDistributionProps) {
  return (
    <div className="space-y-3 md:space-y-4">
      <DistributionBar
        icon={Users}
        label="Masternodes"
        amount={masternodes}
        total={total}
      />
    </div>
  );
}