export const API_CONFIG = {
  BASE_URL: 'https://explorer.win.win',
  CORS_PROXY: 'https://api.allorigins.win/raw?url=',
  TIMEOUT: 10000,
  REFRESH_INTERVAL: 30000,
  FALLBACK: {
    BLOCK_HEIGHT: 1509770,
    HASHRATE: 345095.4578,
    DIFFICULTY: 10236238.50742934,
    CONNECTIONS: 16,
    MASTERNODES: {
      TOTAL: 34,
      ONLINE: 34,
      OFFLINE: 0
    },
    MONEY_SUPPLY: 10828184424.103706,
    PRICE: {
      USD: 0.0125,
      BTC: 5e-10
    },
    DISTRIBUTION: {
      supply: 10828184424.103706,
      t_1_25: { percent: "34.96", total: "3785618834.57379341" },
      t_26_50: { percent: "23.15", total: "2506284707.02082396" },
      t_51_75: { percent: "18.62", total: "2016039517.50973344" },
      t_76_100: { percent: "6.01", total: "650863208.82148635" },
      t_101plus: { percent: "17.26", total: "1869394156.17786980" }
    }
  }
} as const;