import React from 'react';
import type { LucideIcon } from 'lucide-react';
import TwinsAmount from '../../icons/TwinsAmount';
import { formatCompactNumber } from '../../../utils/format/number';

interface DistributionBarProps {
  icon: LucideIcon;
  label: string;
  amount: number;
  total: number;
}

export default function DistributionBar({ icon: Icon, label, amount, total }: DistributionBarProps) {
  const percentage = (amount / total) * 100;
  
  return (
    <div className="p-3 md:p-4 bg-slate-900/50 rounded-lg">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <Icon className="h-3.5 w-3.5 md:h-4 md:w-4 text-green-500" />
          <span className="text-xs md:text-sm text-gray-400">{label}</span>
        </div>
        <span className="text-xs md:text-sm text-green-400">{percentage.toFixed(1)}%</span>
      </div>
      
      <div className="h-1.5 md:h-2 bg-slate-700 rounded-full overflow-hidden">
        <div 
          className="h-full bg-green-500 rounded-full transition-all duration-500"
          style={{ width: `${percentage}%` }}
        />
      </div>
      
      <div className="mt-2 text-right">
        <TwinsAmount 
          amount={formatCompactNumber(amount)}
          size="sm"
          className="text-xs md:text-sm text-gray-400"
        />
      </div>
    </div>
  );
}