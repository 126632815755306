import React from 'react';

interface BlockCounterProps {
  targetValue: number;
}

export default function BlockCounter({ targetValue }: BlockCounterProps) {
  return (
    <span className="text-lg">
      {Math.floor(targetValue).toLocaleString()}
    </span>
  );
}