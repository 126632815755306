import React from 'react';
import { PieChart, AlertCircle } from 'lucide-react';
import { useDistribution } from '../../../hooks/useDistribution';
import DistributionChart from './DistributionChart';
import DistributionList from './DistributionList';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export default function DistributionWidget() {
  const { data, loading, error } = useDistribution();
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (loading) {
    return (
      <div className="h-full col-span-1 md:col-span-2 min-w-[300px] bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10 animate-pulse">
        <div className="h-[400px]"></div>
      </div>
    );
  }

  return (
    <div className="h-full col-span-1 md:col-span-2 min-w-[300px] bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center space-x-2 mb-4 md:mb-6">
        <PieChart className="h-5 w-5 md:h-6 md:w-6 text-green-500" />
        <h3 className="text-lg md:text-xl font-semibold">Distribution</h3>
        {error && (
          <span className="flex items-center gap-1 text-xs text-red-400 ml-auto">
            <AlertCircle className="h-3 w-3" />
            Using cached data
          </span>
        )}
      </div>

      <div className={`${isMobile ? 'space-y-6' : 'grid grid-cols-2 gap-6'} h-[calc(100%-3.5rem)]`}>
        <DistributionList data={data} />
        {!isMobile && <DistributionChart data={data} />}
      </div>
    </div>
  );
}