import React from 'react';
import { Activity, AlertCircle } from 'lucide-react';
import { useTransactionActivity } from '../../../hooks/useTransactionActivity';
import TransactionChart from './TransactionChart';
import TransactionDistribution from './TransactionDistribution';
import TransactionList from './TransactionList';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export default function TransactionActivityWidget() {
  const { transactions, stats, loading, error } = useTransactionActivity();
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (loading) {
    return (
      <div className="h-full col-span-1 md:col-span-2 min-w-[300px] bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10">
        <div className="flex items-center space-x-2 mb-4 md:mb-6">
          <Activity className="h-5 w-5 md:h-6 md:w-6 text-green-500" />
          <h3 className="text-lg md:text-xl font-semibold">Last 100 Transactions</h3>
        </div>
        <div className="space-y-4 h-[400px]">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-20 bg-slate-900/50 rounded-lg animate-pulse" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full col-span-1 md:col-span-2 min-w-[300px] bg-slate-800/50 rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center space-x-2 mb-4 md:mb-6">
        <Activity className="h-5 w-5 md:h-6 md:w-6 text-green-500" />
        <h3 className="text-lg md:text-xl font-semibold">Last 100 Transactions</h3>
        {error && (
          <span className="flex items-center gap-1 text-xs text-red-400 ml-auto">
            <AlertCircle className="h-3 w-3" />
            Failed to load transactions
          </span>
        )}
      </div>

      <div className={`${isMobile ? 'space-y-6' : 'grid grid-cols-2 gap-6'} h-[calc(100%-3.5rem)]`}>
        <div className="space-y-6">
          <TransactionChart 
            volumeHistory={stats.volumeHistory}
            countHistory={stats.countHistory}
          />
          <TransactionDistribution distribution={stats.rewardDistribution} />
        </div>
        <div className="h-full overflow-y-auto">
          <TransactionList transactions={transactions.slice(0, isMobile ? 4 : 6)} />
        </div>
      </div>
    </div>
  );
}