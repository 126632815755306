import React from 'react';
import EndpointCard from './EndpointCard';
import type { ApiEndpoint } from '../../types/api';

interface ApiSectionProps {
  title: string;
  description: string;
  endpoints: ApiEndpoint[];
}

export default function ApiSection({ title, description, endpoints }: ApiSectionProps) {
  return (
    <section>
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2">{title}</h2>
        <p className="text-gray-400">{description}</p>
      </div>
      
      <div className="space-y-6">
        {endpoints.map((endpoint, index) => (
          <EndpointCard key={index} {...endpoint} />
        ))}
      </div>
    </section>
  );
}