import React from 'react';
import { Server, Timer } from 'lucide-react';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';
import { formatUptime } from '../../utils/format/uptime';
import { motion } from 'framer-motion';

interface NodeStatusWidgetProps {
  nodes: MasternodeWithStats[];
}

export default function NodeStatusWidget({ nodes }: NodeStatusWidgetProps) {
  const totalNodes = nodes.length;
  const activeNodes = nodes.filter(node => node.status === 'ENABLED').length;
  const avgUptime = nodes.reduce((sum, node) => sum + node.activetime, 0) / totalNodes;
  const { primary: uptimePrimary, detailed: uptimeDetailed } = formatUptime(avgUptime);
  const activePercentage = (activeNodes / totalNodes) * 100;
  
  return (
    <div className="bg-slate-800/50 rounded-lg md:rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center gap-2 md:gap-3 mb-4">
        <Server className="h-4 w-4 md:h-5 md:w-5 text-green-500" />
        <span className="text-xs md:text-sm text-gray-400 font-medium">Node Status</span>
      </div>

      <div className="space-y-6">
        {/* Active Nodes */}
        <div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-400">Active Nodes</span>
            <span className="text-sm font-mono text-green-400">
              {activeNodes}/{totalNodes}
            </span>
          </div>
          <div className="h-2 bg-slate-700 rounded-full overflow-hidden">
            <motion.div 
              className="h-full bg-gradient-to-r from-green-500 to-green-400 rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${activePercentage}%` }}
              transition={{ duration: 1, ease: "easeOut" }}
            />
          </div>
          <div className="mt-1 text-right">
            <span className="text-xs font-mono text-gray-400">
              {activePercentage.toFixed(1)}% online
            </span>
          </div>
        </div>

        {/* Average Uptime */}
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Timer className="h-3.5 w-3.5 text-green-500" />
            <span className="text-sm text-gray-400">Average Uptime</span>
          </div>
          <div className="text-lg md:text-xl font-mono text-green-400">
            {uptimePrimary}
          </div>
          <div className="text-xs text-gray-400 mt-1">
            {uptimeDetailed}
          </div>
        </div>
      </div>
    </div>
  );
}