import React from 'react';

interface StatRowProps {
  icon: React.ReactNode;
  label: string;
  value: React.ReactNode;
}

export default function StatRow({ icon, label, value }: StatRowProps) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-1.5">
        <span className="text-green-500">{icon}</span>
        <span className="text-xs text-green-500/80 font-mono uppercase">{label}</span>
      </div>
      <div className="font-mono text-green-400">{value}</div>
    </div>
  );
}