import React from 'react';
import { Shield } from 'lucide-react';

export default function PrivacyHeader() {
  return (
    <div className="text-center mb-16">
      <div className="flex items-center justify-center gap-3 mb-6">
        <Shield className="h-10 w-10 text-green-500" />
        <h1 className="text-4xl font-bold">Privacy Notice</h1>
      </div>
      <p className="text-gray-400">
        This privacy notice outlines how we collect, use, and protect your information.
      </p>
    </div>
  );
}