import { useState, useEffect } from 'react';
import { API_CONFIG } from '../api/config';

const DEV_FUND_ADDRESS = 'WmXhHCV6PjXjxJdSXPeC8e4PrY8qTQMBFg';

export function useDevFundBalance() {
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchBalance() {
      try {
        const response = await fetch(`${API_CONFIG.CORS_PROXY}${encodeURIComponent(
          `${API_CONFIG.BASE_URL}/ext/getbalance/${DEV_FUND_ADDRESS}`
        )}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (mounted) {
          setBalance(Number(data));
          setError(null);
        }
      } catch (err) {
        console.warn('Failed to fetch dev fund balance:', err);
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch balance'));
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchBalance();
    const interval = setInterval(fetchBalance, API_CONFIG.REFRESH_INTERVAL);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  return { balance, loading, error };
}