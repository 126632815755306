import React from 'react';

interface PrivacySectionProps {
  title: string;
  content: string | string[];
}

export default function PrivacySection({ title, content }: PrivacySectionProps) {
  return (
    <section className="prose prose-invert max-w-none">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      {Array.isArray(content) ? (
        content.map((paragraph, index) => (
          <p key={index} className="text-gray-300 leading-relaxed mb-4">
            {paragraph}
          </p>
        ))
      ) : (
        <p className="text-gray-300 leading-relaxed">{content}</p>
      )}
    </section>
  );
}