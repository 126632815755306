import React from 'react';
import { Coins } from 'lucide-react';
import type { MasternodeWithStats } from '../../hooks/useMasternodes';
import TwinsAmount from '../common/TwinsAmount';
import { motion } from 'framer-motion';

interface CollateralWidgetProps {
  nodes: MasternodeWithStats[];
  totalSupply: number;
}

export default function CollateralWidget({ nodes, totalSupply }: CollateralWidgetProps) {
  const totalCollateral = nodes.reduce((sum, node) => sum + (node.tier || 0), 0);
  const collateralPercentage = (totalCollateral / totalSupply) * 100;
  
  return (
    <div className="bg-slate-800/50 rounded-lg md:rounded-xl p-4 md:p-6 border border-green-500/10">
      <div className="flex items-center gap-2 md:gap-3 mb-4">
        <Coins className="h-4 w-4 md:h-5 md:w-5 text-green-500" />
        <span className="text-xs md:text-sm text-gray-400 font-medium">Total Collateral</span>
      </div>

      <div className="space-y-4">
        <div>
          <TwinsAmount 
            amount={totalCollateral}
            className="text-lg md:text-2xl text-green-400"
            iconSize={24}
            showDecimals={true}
          />
          <div className="text-sm text-gray-400 mt-1">
            {collateralPercentage.toFixed(2)}% of total supply
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex justify-between text-xs text-gray-400">
            <span>0</span>
            <TwinsAmount 
              amount={totalSupply} 
              showDecimals={true}
              className="text-xs"
            />
          </div>
          <div className="h-2 bg-slate-700 rounded-full overflow-hidden">
            <motion.div 
              className="h-full bg-gradient-to-r from-green-500 to-green-400 rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${collateralPercentage}%` }}
              transition={{ duration: 1, ease: "easeOut" }}
            />
          </div>
          <div className="flex justify-between items-center">
            <div className="text-xs text-gray-400">
              Network Collateral Ratio
            </div>
            <div className="text-sm font-mono text-green-400">
              {collateralPercentage.toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}