export function formatUptime(seconds: number): {
  primary: string;
  detailed: string;
} {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const months = Math.floor(days / 30.44); // Average month length

  // Primary display (just days)
  const primary = `${days}d`;

  // Detailed breakdown
  const parts = [];
  if (months > 0) parts.push(`${months}m`);
  if (days % 30 > 0) parts.push(`${days % 30}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);

  const detailed = parts.join(' ') || '< 1m';

  return { primary, detailed };
}