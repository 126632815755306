import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import type { Masternode } from '../../api/masternodes';

interface MasternodeListHeaderProps {
  sortField: keyof Masternode;
  sortDirection: 'asc' | 'desc';
  onSort: (field: keyof Masternode) => void;
}

const COLUMNS = [
  { field: 'rank', label: 'Rank' },
  { field: 'addr', label: 'Address' },
  { field: 'activetime', label: 'Active Time' },
  { field: 'tier', label: 'Collateral' },
  { field: 'rewards', label: 'Daily Rewards' },
  { field: 'lastpaid', label: 'Last Reward' },
  { field: 'frequency', label: 'Frequency' }
] as const;

export default function MasternodeListHeader({ 
  sortField, 
  sortDirection, 
  onSort 
}: MasternodeListHeaderProps) {
  return (
    <tr className="border-b border-green-500/10">
      {COLUMNS.map(({ field, label }) => (
        <th 
          key={field}
          className={`px-6 py-4 text-left text-sm font-semibold text-gray-400 ${
            field === 'tier' || field === 'rewards' ? 'text-right' : ''
          }`}
        >
          <button 
            className="flex items-center gap-2 hover:text-white"
            onClick={() => onSort(field)}
          >
            {label}
            {sortField === field && (
              sortDirection === 'asc' ? 
                <ChevronUp className="h-4 w-4" /> : 
                <ChevronDown className="h-4 w-4" />
            )}
          </button>
        </th>
      ))}
    </tr>
  );
}