import React from 'react';
import { Terminal, Timer } from 'lucide-react';
import BlockCounter from './BlockCounter';
import StatRow from './StatRow';

interface BlockStatsProps {
  blockHeight: number;
  blockTime: string;
}

export default function BlockStats({ blockHeight, blockTime }: BlockStatsProps) {
  return (
    <div className="space-y-2">
      <StatRow
        icon={<Terminal className="h-3 w-3" />}
        label="Block"
        value={<BlockCounter targetValue={blockHeight} />}
      />
      <StatRow
        icon={<Timer className="h-3 w-3" />}
        label="Age"
        value={blockTime}
      />
    </div>
  );
}