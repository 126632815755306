import type { ApiEndpoint } from '../types/api';

export const CORE_API_ENDPOINTS: ApiEndpoint[] = [
  {
    name: 'getdifficulty',
    description: 'Returns the current difficulty.',
    endpoint: '/api/getdifficulty',
    example: '/api/getdifficulty'
  },
  {
    name: 'getconnectioncount',
    description: 'Returns the number of connections the block explorer has to other nodes.',
    endpoint: '/api/getconnectioncount',
    example: '/api/getconnectioncount'
  },
  {
    name: 'getblockcount',
    description: 'Returns the current block index.',
    endpoint: '/api/getblockcount',
    example: '/api/getblockcount'
  },
  {
    name: 'getblockhash',
    description: 'Returns the hash of the block at specified index.',
    endpoint: '/api/getblockhash',
    example: '/api/getblockhash?index=64152',
    params: [
      {
        name: 'index',
        description: 'Block height (index 0 is the genesis block)'
      }
    ]
  },
  {
    name: 'getblock',
    description: 'Returns information about the block with the given hash.',
    endpoint: '/api/getblock',
    example: '/api/getblock?hash=775d67da29dd6553268061f86368d06654944dd5d5c61db4c97e4c7960c11a74',
    params: [
      {
        name: 'hash',
        description: 'The block hash'
      }
    ]
  },
  {
    name: 'getrawtransaction',
    description: 'Returns raw transaction representation for given transaction id.',
    endpoint: '/api/getrawtransaction',
    example: '/api/getrawtransaction?txid=6cb3babd256de253f926f10bc8574dadf0a3e2fc8380107b81eb07c67d1e73ed&decrypt=1',
    params: [
      {
        name: 'txid',
        description: 'The transaction id'
      },
      {
        name: 'decrypt',
        description: 'Set to 0 for raw hex, 1 for decoded transaction (optional)'
      }
    ]
  },
  {
    name: 'getnetworkhashps',
    description: 'Returns the current network hashrate (hash/s).',
    endpoint: '/api/getnetworkhashps',
    example: '/api/getnetworkhashps'
  },
  {
    name: 'getmasternodecount',
    description: 'Returns the total number of masternodes on the network.',
    endpoint: '/api/getmasternodecount',
    example: '/api/getmasternodecount'
  }
];

export const EXTENDED_API_ENDPOINTS: ApiEndpoint[] = [
  {
    name: 'getmoneysupply',
    description: 'Returns current money supply.',
    endpoint: '/ext/getmoneysupply',
    example: '/ext/getmoneysupply'
  },
  {
    name: 'getdistribution',
    description: 'Returns wealth distribution stats.',
    endpoint: '/ext/getdistribution',
    example: '/ext/getdistribution'
  },
  {
    name: 'getaddress',
    description: 'Returns information for given address.',
    endpoint: '/ext/getaddress/:hash',
    example: '/ext/getaddress/ELvb8AZRgHmdsDnD1HYFwbSY4UkPhoECCW',
    params: [
      {
        name: 'hash',
        description: 'The address hash'
      }
    ]
  },
  {
    name: 'getbalance',
    description: 'Returns current balance of given address.',
    endpoint: '/ext/getbalance/:hash',
    example: '/ext/getbalance/ELvb8AZRgHmdsDnD1HYFwbSY4UkPhoECCW',
    params: [
      {
        name: 'hash',
        description: 'The address hash'
      }
    ]
  },
  {
    name: 'getlasttxs',
    description: 'Returns last transactions greater than specified minimum amount.',
    endpoint: '/ext/getlasttxs/:min/:start/:length',
    example: '/ext/getlasttxs/100/0/100',
    params: [
      {
        name: 'min',
        description: 'Minimum transaction amount'
      },
      {
        name: 'start',
        description: 'Starting from index'
      },
      {
        name: 'length',
        description: 'Number of transactions to return (max 100)'
      }
    ]
  }
];

export const LINKING_ENDPOINTS: ApiEndpoint[] = [
  {
    name: 'Transaction',
    description: 'Direct link to transaction details.',
    endpoint: '/tx/:txid',
    example: '/tx/6cb3babd256de253f926f10bc8574dadf0a3e2fc8380107b81eb07c67d1e73ed',
    params: [
      {
        name: 'txid',
        description: 'The transaction id'
      }
    ]
  },
  {
    name: 'Block',
    description: 'Direct link to block details.',
    endpoint: '/block/:hash',
    example: '/block/775d67da29dd6553268061f86368d06654944dd5d5c61db4c97e4c7960c11a74',
    params: [
      {
        name: 'hash',
        description: 'The block hash'
      }
    ]
  },
  {
    name: 'Address',
    description: 'Direct link to address details.',
    endpoint: '/address/:hash',
    example: '/address/ELvb8AZRgHmdsDnD1HYFwbSY4UkPhoECCW',
    params: [
      {
        name: 'hash',
        description: 'The address hash'
      }
    ]
  }
];