import React from 'react';
import { ArrowRight, Users } from 'lucide-react';
import { formatAmount } from '../../../utils/format/number';
import { formatDistanceToNow } from '../../../utils/time';
import type { Transaction } from '../../../api/transactions';

interface TransactionListProps {
  transactions: Transaction[];
}

export default function TransactionList({ transactions }: TransactionListProps) {
  return (
    <div className="space-y-3 md:space-y-4">
      {transactions.map(tx => (
        <div 
          key={tx.txid}
          className="p-3 md:p-4 bg-slate-900/50 rounded-lg hover:bg-slate-900/70 transition-colors"
        >
          <div className="flex items-center justify-between gap-2 md:gap-4">
            <div className="min-w-0 flex-1">
              <div className="font-mono text-xs md:text-sm text-green-400 truncate">
                {tx.txid}
              </div>
              <div className="text-xs text-gray-400 mt-1">
                Block #{tx.blockindex}
              </div>
            </div>
            <div className="flex items-center gap-2 md:gap-4 shrink-0">
              <div className="flex items-center gap-1 text-gray-400">
                <Users className="h-3 w-3" />
                <span className="text-xs">{tx.recipients}</span>
              </div>
              <ArrowRight className="h-4 w-4 text-gray-600 hidden md:block" />
              <div className="text-right">
                <div className="font-mono text-sm md:text-base text-green-400">
                  {formatAmount(tx.amount)}
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  {formatDistanceToNow(tx.timestamp * 1000)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {transactions.length === 0 && (
        <div className="text-center py-8 text-gray-400">
          No transactions found
        </div>
      )}
    </div>
  );
}