import React from 'react';
import { useNetwork } from '../../contexts/NetworkContext';
import BlockStats from './BlockStats';
import { formatBlockTime } from '../../utils/time/blockTime';

export default function BlockHeightIndicator() {
  const { data, loading } = useNetwork();
  
  if (loading) {
    return (
      <div className="w-full max-w-[280px] mx-auto">
        <div className="bg-slate-900/80 backdrop-blur-sm rounded-lg border border-green-500/20 p-4">
          <div className="animate-pulse space-y-2">
            <div className="h-6 bg-slate-700 rounded w-24" />
            <div className="h-6 bg-slate-700 rounded w-32" />
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="w-full max-w-[280px] mx-auto">
      <div className="bg-slate-900/80 backdrop-blur-sm rounded-lg border border-green-500/20 p-4">
        <BlockStats 
          blockHeight={data.blockcount}
          blockTime={formatBlockTime(data.blockcount)}
        />
      </div>
    </div>
  );
}