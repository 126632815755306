import { CHAIN_START } from '../../constants/network';

export function formatBlockTime(blockHeight: number): string {
  const now = Date.now();
  const chainAge = now - CHAIN_START.getTime();
  const avgBlockTime = chainAge / (blockHeight * 1000);

  const seconds = Math.floor(chainAge / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30.44);
  const years = Math.floor(days / 365.25);

  return [
    years > 0 ? `${years}y` : null,
    months % 12 > 0 ? `${months % 12}m` : null,
    days % 30 > 0 ? `${days % 30}d` : null,
    hours % 24 > 0 ? `${hours % 24}h` : null,
    minutes % 60 > 0 ? `${minutes % 60}m` : null,
    seconds % 60 > 0 ? `${seconds % 60}s` : null
  ]
    .filter(Boolean)
    .join(' ');
}