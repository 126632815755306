import React from 'react';
import { Users } from 'lucide-react';
import { formatAmount } from '../../../utils/format/number';
import type { DistributionData } from '../../../types/distribution';

interface DistributionListProps {
  data: DistributionData;
}

const RANGE_LABELS = {
  t_1_25: 'Top 1-25',
  t_26_50: 'Top 26-50',
  t_51_75: 'Top 51-75',
  t_76_100: 'Top 76-100',
  t_101plus: 'Top 101+'
} as const;

export default function DistributionList({ data }: DistributionListProps) {
  const ranges = ['t_1_25', 't_26_50', 't_51_75', 't_76_100', 't_101plus'] as const;

  return (
    <div className="space-y-4">
      {ranges.map((range, index) => (
        <div key={range} className="p-4 bg-slate-900/50 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <Users className="h-4 w-4 text-green-500" />
              <span className="text-sm text-gray-400">{RANGE_LABELS[range]}</span>
            </div>
            <span className="text-sm text-green-400">{data[range].percent}%</span>
          </div>
          <div className="h-2 bg-slate-700 rounded-full overflow-hidden">
            <div 
              className="h-full rounded-full transition-all duration-500"
              style={{ 
                width: `${data[range].percent}%`,
                backgroundColor: `hsl(${120 - index * 15}, 60%, ${45 - index * 5}%)`
              }}
            />
          </div>
          <div className="mt-2 text-right">
            <span className="text-xs text-gray-400 font-mono">
              {formatAmount(parseFloat(data[range].total))} TWINS
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}